export const loginTheme = {
  qzrcw: {
    title: '衢州人才网管理端',
    name: '衢州人才网',
    logo: ""
  },
  qzrfy: {
    title: '衢州人服园管理端',
    name: '衢州人服园',
    logo: ""
  }
}

export const loginThemeKeys = Object.keys(loginTheme)