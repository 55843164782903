import { lazy } from 'react';

import type { routeParams } from './createRoutes';

import { loginThemeKeys } from "../utils/loginTheme"


const Login = lazy(() => import('pages/Login/index'));
const CftsApp = lazy(() => import('pages/index'));

const publicRoutes: Array<routeParams> = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/login/',
    component: Login,
  },
  ...loginThemeKeys.map(c => ({
    path: `/${c}/`,
    component: Login
  })),
  {
    path: '/platform/',
    redirect: '/login/',
    component: CftsApp,
  }
];

export default publicRoutes;