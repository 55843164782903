import React, { useEffect, useMemo } from 'react';
import zh_CN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Modal } from 'antd';
// import Routes from './router';
import createRoutes from 'router/createRoutes';
import { UserContext, useUser } from './models/user';
import { RouteConfigContext, useRouteConfig } from './router/routes';
import { useValley, ValleyContext } from './models/valley';

import './App.less';

dayjs.locale('zh-cn');

function App() {
  const store = useUser();
  const valleyStore = useValley();
  const routeStore = useRouteConfig();
  window.controllerMap = new Map();
  window.logout = store.logout;
  useEffect(() => {
    if (store.user.isAuthed && !store.user.username && !store.user.logged) {
      store.getUserInfo(store.user.user_id);
      valleyStore.getValleyInfo(store.user.default_valley_id)
      routeStore.getRouteConfig(store.user.default_valley_id)
    }
  }, [
    store.user.username,
    store.user.user_id,
    store.user.isAuthed,
    store.user.default_valley_id,
    store.user.logged
  ])

  let Routes = useMemo(() => createRoutes(routeStore.routeState.routes), [routeStore.routeState])
  return (
    <ConfigProvider locale={zh_CN}>
      <div className="App">
        <UserContext.Provider value={store}>
          <ValleyContext.Provider value={valleyStore}>
            <RouteConfigContext.Provider value={routeStore}>
              <BrowserRouter basename='/guanli'>
                <Routes />
              </BrowserRouter>
            </RouteConfigContext.Provider>
          </ValleyContext.Provider>
        </UserContext.Provider>
        {/* <Modal title="公告" open={true} width="450px" footer={null} closable={false}>
          <p>四省边际人才网停机升级通知:</p>
          <p style={{ textIndent: '2rem' }}>
            因系统升级维护，平台从2024年9月21日0时起至2024年10月8日0时，将对服务器及数据中心设备进行停机维护。请合理安排平台使用时间，给您带来的不便，敬请谅解。维护期间如有岗位需求，请联系小边
            19548009927，相关招聘信息将通过微信群等方式推送。
          </p>
          <p style={{ textIndent: '2rem' }}>
            为补偿在停机期间无法使用四省边际人才网的企业用户，所有的企业会在停机结束后免费参与平台所有新增校招活动，并且无偿获取活动简历，感谢理解支持。</p>
          <p style={{ textAlign: 'right' }}> 四省边际人才网 </p>
          <p style={{ textAlign: 'right' }}>2024年9月6日</p>
        </Modal> */}
      </div>
    </ConfigProvider>
  );
}

export default App;
