export const HOST = (() => {
  if (process.env.NODE_ENV === "development") {
    //开发调试
    return 'http://192.168.31.60:8001'
    // return 'http://192.168.31.177:8001'
    // return 'http://192.168.31.177:8000'
    //  return "http://192.168.31.21:8000";
    // return "http://192.168.31.55:8002";
    // return "http://192.168.31.59:8001";
    // return "http://192.168.31.8:8007"
  }
  //线上s
  // return "https://api-cfts.ssbjrcw.com"
  // return "http://10.27.172.91:8001"
  return "https://www.ssbjrcw.com/api-cfts"

})()

export const BASE_URL = `${HOST}/api`