import { useState } from 'react';
import { getDataWithToken, postDataWithToken, to } from 'utils/request';
import { message } from 'antd';
enum requestUrl {
  talentRoom = '/circles/{valley_id}/all_talent_room_list/',
  talentRoomCount = '/circles/{valley_id}/all_talent_room_count/',
  talentRoomDetail = '/circles/{valley_id}/talent_room/{talent_room_id}/',
  talentRoomExport = '/circles/{valley_id}/talent_room_report/',
}

export function useFlats() {
  const valleyId = localStorage.getItem('default_valley_id');
  let [talentRoom, setTalentRooms] = useState<any>({ loading: false, results: [], count: 0 });
  let [talentRoomDetail, setTalentRoomDetail] = useState<any>({ loading: false });

  let [talentRoomCount, setTalentRoomCount] = useState<any>({ loading: false });
  let [exportLoading, setExportLoading] = useState<any>(false);

  async function getTalentRoom(params?: any) {
    let url = requestUrl.talentRoom.replace('{valley_id}', String(valleyId));
    setTalentRooms({ ...talentRoom, loading: true });

    let [error, res] = await to(getDataWithToken(url, { params }));

    if (!error) {
      return setTalentRooms({ ...res.data, loading: false });
    }
    setTalentRooms({ ...talentRoom, loading: false });
    return Promise.reject();
  }
  async function getTalentRoomDetail(id?: any) {
    let url = requestUrl.talentRoomDetail
      .replace('{valley_id}', String(valleyId))
      .replace('{talent_room_id}', String(id));
    setTalentRoomDetail({ ...talentRoomDetail, loading: true });

    let [error, res] = await to(getDataWithToken(url));

    if (!error) {
      return setTalentRoomDetail({ ...res.data, loading: false });
    }
    setTalentRoomDetail({ ...talentRoomDetail, loading: false });
    return Promise.reject();
  }
  async function getTalentRoomCount(params?: any) {
    let url = requestUrl.talentRoomCount.replace('{valley_id}', String(valleyId));
    setTalentRoomCount({ ...talentRoomCount, loading: true });

    let [error, res] = await to(getDataWithToken(url, { params }));

    if (!error) {
      return setTalentRoomCount({ ...res.data, loading: false });
    }
    setTalentRoomCount({ ...talentRoomCount, loading: false });
    return Promise.reject();
  }
  async function exportTalentRoom(params?: any) {
    let url = requestUrl.talentRoomExport.replace('{valley_id}', String(valleyId));
    setExportLoading(true);

    let [error, res] = await to(getDataWithToken(url, { params }));
// 
    if (!error) {
      message.success('导出成功')
			window.open(res.data.excel_file_url)
      return setExportLoading(false);
    }
    setExportLoading(false);
    return Promise.reject();
  }
  async function postTalentRoom(id: any, data?: any) {
    let url = requestUrl.talentRoomDetail
      .replace('{valley_id}', String(valleyId))
      .replace('{talent_room_id}', String(id));

    let [error, res] = await to(postDataWithToken(url, {data}));

    if (!error) {
      return res.data;
    }
    return Promise.reject();
  }

  return {
    getTalentRoom,
    talentRoom,
    getTalentRoomCount,
    talentRoomCount,
    postTalentRoom,
    getTalentRoomDetail,
    talentRoomDetail,
    exportTalentRoom,
    exportLoading,

  };
}
