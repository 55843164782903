import axios from 'axios';
import { message } from 'antd';
import { BASE_URL } from './BASE';
import dayjs from "dayjs";

axios.defaults.baseURL = BASE_URL;

// 请求拦截器
// axios.interceptors.request.use(function (config) {
//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });

// 响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 记录最后一次请求成功的时间
    localStorage.setItem('lastOperateDate', dayjs().format());
    // 设置计时器 5s 触发一次
    if (window.leisureCountDown) clearTimeout(window.leisureCountDown);
    window.leisureCountDown = setTimeout(timeoutFn, (5 * 1000));

    return response;
    // 闲置超时退出登录 
    function timeoutFn() {
      window.leisureCountDown = null;
      let lastOperateDate = localStorage.getItem('lastOperateDate');
      if (window.logout && (!lastOperateDate || (dayjs().diff(lastOperateDate, 'minute') >= 30))) {
        // setTimeout(window.logout,3000)
        message.error('长时间未操作，将退出登录');
      } else {
        // 进入下一个循环
        window.leisureCountDown = setTimeout(timeoutFn, (5 * 1000));
      }
    }
  },
  function (error) {
    if (error.response && error.response.data) {
      if (error.response.data && error.response.status === 400) {
        error.response.data.reason && message.error(error.response.data.reason);
      } else if (error.response.status === 403)
        message.error(error.response.data.reason || '验证失效，请退出重新登录')
      else if (error.response.status === 500) message.error('服务器连接错误')
    } else if (error.code && error.code === 'ERR_CANCELED') {
      return Promise.reject(error);
    } else {
      message.error('服务器连接错误');
    }
    return Promise.reject(error);
  }
);

export function to(promise: Promise<any>) {
  return promise
    .then(data => {
      return [null, data];
    })
    .catch(err => [err]);
}

function buildStringArr(params: any): Array<any> {
  let str = [];
  if (!params) {
    return [];
  }
  for (let p in params) {
    if (params.hasOwnProperty(p)) {
      if (Array.isArray(params[p])) {
        let s = [];
        let list = params[p];
        for (let i in list) {
          s.push(encodeURIComponent(list[i]));
        }
        str.push(encodeURIComponent(p) + '=' + s.join(','));
      } else {
        if (params[p] !== null)
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
      }
    }
  }
  return str;
}

export const createCookieString = (params: any) => {
  let str = buildStringArr(params);
  return str.join('&');
};

export const createParamsString = (params: any, spiltor: string = ',') => {
  let str = buildStringArr(params);
  return '?' + str.join('&');
};

function saveControllerMapToWindow(key: string, controller: AbortController) {
  let { controllerMap } = window;
  let beforeController = controllerMap.get(key);
  if (beforeController) beforeController.abort();
  controllerMap.set(key, controller);
}

// 不需要token的请求
export async function getData(
  url: string,
  options?: { params?: object; concurrent?: boolean }
): Promise<any> {
  const { params, concurrent } = options || {};
  let paramsString = createParamsString(params);
  let requestUrl = `${url}${paramsString}`;

  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(requestUrl, controller);
    return axios
      .get(requestUrl)

      .finally(() => {
        controller.abort();
        window.controllerMap.has(url) && window.controllerMap.delete(url);
      });
  }
  return axios.get(requestUrl);
}

export async function deleteData(
  url: string,
  options?: { params?: object; concurrent?: boolean }
): Promise<any> {
  const { params, concurrent } = options || {};
  let paramsString = createParamsString(params);
  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(url, controller);
    return axios.delete(`${url}${paramsString}`).finally(() => {
      controller.abort();
      window.controllerMap.has(url) && window.controllerMap.delete(url);
    });
  }
  return axios.delete(`${url}${paramsString}`);
}

export async function postData(
  url: string,
  options: { data: object; concurrent?: boolean }
): Promise<any> {
  const { data, concurrent } = options || {};
  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(url, controller);
    return axios.post(url, data).finally(() => {
      controller.abort();
      window.controllerMap.has(url) && window.controllerMap.delete(url);
    });
  }
  return axios.post(url, data);
}

export async function putData(
  url: string,
  options: { data: object; concurrent?: boolean }
): Promise<any> {
  const { data, concurrent } = options || {};
  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(url, controller);
    return axios.put(url, data).finally(() => {
      controller.abort();
      window.controllerMap.has(url) ?? window.controllerMap.delete(url);
    });
  }
  return axios.put(url, data);
}

// 需要token的请求
export async function getDataWithToken(
  url: string,
  options?: { params?: object; concurrent?: boolean;responseType?:any }
): Promise<any> {
  const { params, concurrent } = options || {};
  const token = localStorage.getItem('ssbj_admin_token') || sessionStorage.getItem('ssbj_admin_token');
  if (!token) return Promise.reject({ status: 403, reason: '登录态丢失' });
  let paramsString = createParamsString(params);
  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(url, controller);
    return axios
      .get(`${url}${paramsString}`, { responseType:options?.responseType,headers: { Authorization: "Bearer " + token }, signal: controller.signal })
      .finally(() => {
        controller.abort();
        window.controllerMap.has(url) && window.controllerMap.delete(url);
      });
  }
  return axios.get(`${url}${paramsString}`, {responseType:options?.responseType, headers: { Authorization: "Bearer " + token } });
}

export async function putDataWithToken(
  url: string,
  options: { data: object; concurrent?: boolean }
): Promise<any> {
  const { data, concurrent } = options || {};
  const token = localStorage.getItem('ssbj_admin_token') || sessionStorage.getItem('ssbj_admin_token');
  if (!token) return Promise.reject({ status: 403, reason: '登录态丢失' });
  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(url, controller);
    return axios
      .put(url, data, { headers: { Authorization: "Bearer " + token }, signal: controller.signal })
      .finally(() => {
        controller.abort();
        window.controllerMap.has(url) && window.controllerMap.delete(url);
      });
  }
  return axios.put(url, data, { headers: { Authorization: "Bearer " + token } });
}

export async function postDataWithToken(
  url: string,
  options: { data?: object; params?: object; concurrent?: boolean }
): Promise<any> {
  const { data, params, concurrent } = options || {};
  const token = localStorage.getItem('ssbj_admin_token') || sessionStorage.getItem('ssbj_admin_token');
  if (!token) return Promise.reject({ status: 403, reason: '登录态丢失' });
  let paramsString = createParamsString(params);

  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(url, controller);
    return axios
      .post(`${url}${paramsString}`, data, { headers: { Authorization: "Bearer " + token }, signal: controller.signal })
      .finally(() => {
        controller.abort();
        window.controllerMap.has(url) && window.controllerMap.delete(url);
      });
  }
  return axios.post(url, data, { headers: { Authorization: "Bearer " + token } });
}

export async function deleteDataWithToken(
  url: string,
  options?: { params?: object; concurrent?: boolean; data?: object }
): Promise<any> {
  const { params, concurrent, data } = options || {};
  const token = localStorage.getItem('ssbj_admin_token') || sessionStorage.getItem('ssbj_admin_token');
  if (!token) {
    return Promise.reject({ status: 403, reason: '登录态丢失' });
  }
  let paramsString = createParamsString(params);

  if (!concurrent) {
    let controller = new AbortController();
    saveControllerMapToWindow(url, controller);
    return axios
      .delete(`${url}${paramsString}`, { headers: { Authorization: "Bearer " + token }, data, signal: controller.signal })
      .finally(() => {
        controller.abort();
        window.controllerMap.has(url) && window.controllerMap.delete(url);
      });
  }
  return axios.delete(`${url}${paramsString}`, { headers: { Authorization: "Bearer " + token } });
}

// 返回控制器的get方法
export async function getDataWithTokenAndController(
  url: string,
  options?: { params?: object }
): Promise<any> {
  const { params } = options || {};
  const token = localStorage.getItem('ssbj_admin_token') || sessionStorage.getItem('ssbj_admin_token');
  if (!token) return Promise.reject({ status: 403, reason: '登录态丢失' });
  let paramsString = createParamsString(params);
  const controller = new AbortController();
  return [
    axios.get(`${url}${paramsString}`, { headers: { Authorization: "Bearer " + token }, signal: controller.signal }),
    controller,
  ];
}
