import React, { useState } from 'react';
import { Card, Table } from 'antd';
import FlatsFilter from './Filter';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
export const talentType = [
  '上年度固定资产投资额排名前十名及其他重点投资项目引进人才', //县招商中心
  '民营工业企业中纳税十强企业引进人才', //县经信局
  '人才分类目录里第 1-5 类人才以及企事业单位引进的副高及以上职称、硕士研究生及以上学历（学位）人才', //县人社局
  '人才工作重点企业的高管、技术研发骨干、重点投资项目引进人才和其他重点人才', //县委人才办
];
const idCardTypeMap: any = {
  1: '身份证',
  4: '护照',
};
const statusMap: any = {
  1: '待审核',
  2: '已驳回',
  3: '已通过',
};
const FlatsList: React.FC<any> = ({ data, count, currentPage, onChange, pageSize }) => {
  const columns: any = [
    {
      title: '申请人',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => {
        return (
          <Link to={`./${record.id}`} rel="opener">
            {text}
          </Link>
        );
      },
    },
    {
      title: '提交企业',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (val: string) => {
        return <span>{val || '无'}</span>;
      },
    },
    {
      title: '企业联系电话',
      dataIndex: 'company_phone_number',
      key: 'company_phone_number',
      render: (val: string) => {
        return <span>{val || '无'}</span>;
      },
    },
    {
      title: '证件类型',
      dataIndex: 'id_card_type',
      key: 'id_card_type',
      render: (val: string, record: any) => {
        return <span>{val ? idCardTypeMap[val] || '无' : '无'}</span>;
      },
    },
    {
      title: '证件号码',
      dataIndex: 'id_card_number',
      key: 'id_card_number',
      render: (val: string) => {
        return <span>{val || '无'}</span>;
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex',
      render: (val: string) => {
        return <span>{val || '无'}</span>;
      },
    },
    {
      title: '人才类型',
      dataIndex: 'apply_type',
      key: 'apply_type',
      render: (val: number) => {
        return <span>{val ? talentType[val - 1] : '无'}</span>;
      },
    },
    {
      title: '附件',
      dataIndex: 'annex_url',
      key: 'annex_url',
      render: (val: string) => {
        return val ? (
          <a target="_blank" href={val}>
            查看附件
          </a>
        ) : (
          '无'
        );
      },
    },

    {
      title: '提交时间',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (val: any) => <span>{dayjs(val).format('YYYY-MM-DD HH:mm')}</span>,
    },
    {
      title: '审核状态',
      dataIndex: 'apply_status',
      key: 'apply_status',
      render: (val: number, { department_audit_status, apply_type }: any) => {
        const valleyId = localStorage.getItem('default_valley_id');

        return (
          <span>
            {val
              ? statusMap[Number(valleyId) === 12 ? val : department_audit_status] || '无'
              : '无'}
          </span>
        );
      },
    },
  ];
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);

  const tableChange = (pagination: any, filters: any, sorter: any) => {
    // pageChange(pagination.current)
    const temp = {
      offset: (pagination.current - 1) * pagination.pageSize,
      order_name: sorter.field,
      order_sort: 'false',
    };

    // console.log('[ pagination ] >', pagination, filters, sorter)
    switch (sorter.order) {
      case 'ascend':
        temp.order_sort = 'true';
        break;
      case 'descend':
        temp.order_sort = 'false';
        break;
      default:
        temp.order_sort = '';
        temp.order_name = '';
        break;
    }
    onParamsChange({ ...temp });
    // setParams(temp)
  };
  const onParamsChange = (v: any) => {
    onChange(v);
    setSelectedKeys([]);
  };
  return (
    <Card>
      <FlatsFilter onChange={onParamsChange} selectedKeys={selectedKeys} />
      <Table
        columns={columns}
        rowSelection={{
          fixed: true,
          preserveSelectedRowKeys: false,
          selectedRowKeys: selectedKeys,
          onChange(v) {
            setSelectedKeys(v);
          },
        }}
        onChange={tableChange}
        dataSource={data}
        rowKey={'id'}
        pagination={{
          total: count,
          current: currentPage,
          pageSize: pageSize,
          showSizeChanger: false,
        }}
      />
    </Card>
  );
};
export default FlatsList;
