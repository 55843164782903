import { createContext, useReducer } from 'react';
import { getDataWithToken, putDataWithToken, to } from '../utils/request';
import type { ValleyContent } from './types/valley';
import { idMap } from 'utils/publicData'

export const ValleyContext = createContext<Partial<ValleyContent>>({});

enum requestUrl {
  valleyInfo = '/career_fairs/valley/{valley_id}/',
}

export function useValley() {

  const initialState = {
    name: '单位名称',
  };
  const [valley, dispatch] = useReducer((state: any, action: any): any => {
    switch (action.type) {
      case 'GET_VALLEY': {
        return {...state, ...action.data, hasSubunit: idMap.includes(action.data?.valley_id || 0)};
      }
      case 'GET_VALLEY_START': {
        return {...state, loading: true};
      }
      case 'GET_VALLEY_END': {
        return {...state, loading: false};
      }
      case 'DELETE_VALLEY': {
        return {};
      }
      default:
        return state;
    }
  }, initialState);

  async function getValleyInfo(valley_id: number) {
    dispatch({type: 'GET_VALLEY_START'});

    let url = requestUrl.valleyInfo.replace('{valley_id}', String(valley_id));
    let [error, res] = await to(getDataWithToken(url));
    if (!error) {
      // setValleyInfo(res.data);
      // console.log(res.data);
      dispatch({type: 'GET_VALLEY', data: res.data});
      dispatch({type: 'GET_VALLEY_END'});
    }
  }

  async function deleteValleyInfo() {
    dispatch({type: 'DELETE_VALLEY'});
  }

  async function putValleyInfo(valley_id: number, data: any) {
    dispatch({type: 'GET_VALLEY_START'});

    let url = requestUrl.valleyInfo.replace('{valley_id}', String(valley_id));
    let [error, res] = await to(putDataWithToken(url, {data}));

    if (!error) {
      // setValleyInfo(res.data);
      return await getValleyInfo(valley_id);
    }
  }

  return {
    valley,
    getValleyInfo,
    putValleyInfo,
    deleteValleyInfo
  };
}
