import dayjs from 'dayjs';

interface loginUserRes {
  token: string;
  user_id: number;
  default_valley_id: number;
}

//保存登录态到本地存储
export const rememberUser = (data: loginUserRes, exdays: number) => {
  sessionStorage.removeItem('ssbj_admin_token');
  sessionStorage.removeItem('ssbj_admin_user_id');
  sessionStorage.removeItem('default_valley_id');

  localStorage.setItem('expiration_time', dayjs(new Date()).add(exdays, 'day').toISOString());
  localStorage.setItem('ssbj_admin_token', data.token);
  localStorage.setItem('ssbj_admin_user_id', String(data.user_id));
  localStorage.setItem('default_valley_id', String(data.default_valley_id));
};

//保存到会话存储
export const setUser = (data: loginUserRes) => {
  localStorage.removeItem('ssbj_admin_token');
  localStorage.removeItem('ssbj_admin_user_id');
  localStorage.removeItem('expiration_time');
  localStorage.removeItem('default_valley_id');

  sessionStorage.setItem('ssbj_admin_user_id', String(data.user_id));
  sessionStorage.setItem('ssbj_admin_token', data.token);
  sessionStorage.setItem('default_valley_id', String(data.default_valley_id));
};

//删除登录态
export const deleteUser = () => {
  localStorage.removeItem('ssbj_admin_token');
  localStorage.removeItem('ssbj_admin_user_id');
  localStorage.removeItem('default_valley_id');
  localStorage.removeItem('expiration_time');
  sessionStorage.removeItem('ssbj_admin_token');
  sessionStorage.removeItem('ssbj_admin_user_id');
  sessionStorage.removeItem('default_valley_id');
};

//从本地或会话获取登录态
export const getUserToCache = () => {
  let token = localStorage.getItem('ssbj_admin_token');
  let userId = localStorage.getItem('ssbj_admin_user_id') ? Number(localStorage.getItem('ssbj_admin_user_id')) : null;
  let valleyId = localStorage.getItem('default_valley_id') ? Number(localStorage.getItem('default_valley_id')) : null;
  let expirationTime = localStorage.getItem('expiration_time');
  if (token && userId && valleyId && expirationTime && dayjs(expirationTime).diff(new Date(), 'day') >= 0) {
    rememberUser({token, user_id: userId, default_valley_id: valleyId}, 7);
    return {isAuthed: true, token, user_id: userId, default_valley_id: valleyId, loginLoading: false};
  }

  token = sessionStorage.getItem('ssbj_admin_token');
  userId = sessionStorage.getItem('ssbj_admin_user_id') ? Number(sessionStorage.getItem('ssbj_admin_user_id')) : null;
  valleyId = sessionStorage.getItem('default_valley_id') ? Number(sessionStorage.getItem('default_valley_id')) : null;
  if (token) return {
    isAuthed: true,
    token,
    user_id: userId,
    default_valley_id: valleyId,
    loginLoading: false,
    logged: false
  };
  return {isAuthed: false, token: '', user_id: null, default_valley_id: null, loginLoading: false, logged: false};
};
//共享sessionStorage
export const shareSessionStorage = () => {
  return new Promise((resolve) => {
    console.log(window.opener)
    if (!sessionStorage.length) {

      localStorage.setItem('getSessionStorage', Date.now() + '');
    }
    window.addEventListener('storage', function (event: any) {
      if (event.key === 'getSessionStorage') {
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        localStorage.removeItem('sessionStorage');
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        console.log(1212, event)
        var data = JSON.parse(event.newValue);
        for (let key in data) {
          // (window as any).memoryStorage[key] = data[key];
          sessionStorage.setItem(key, data[key]);
        }
        resolve?.(data);
      } else {
        resolve(JSON.stringify(sessionStorage));
      }
    });
  });
};
