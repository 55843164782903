import React, { useEffect, useState } from 'react';
import FlatsHead from 'components/Flats/Head';
import FlatsList from 'components/Flats/List';
// import { Outlet } from 'react-router';
import './index.less';
import { useFlats } from 'models/flats';

import { useParams } from 'react-router';
import { Descriptions, Button, Space, Spin, message, Modal, Input } from 'antd';
import dayjs from 'dayjs';
import { talentType } from 'components/Flats/List';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const idCardTypeMap: any = {
  1: '身份证',
  4: '护照',
};
const statusMap: any = {
  1: '待审核',
  2: '已驳回',
  3: '已通过',
};
const FlatTalentDetail = () => {
  const { id } = useParams();
  const { getTalentRoomDetail, talentRoomDetail, postTalentRoom } = useFlats();
  const [applyStatus, setApplyStatus] = useState(null);
  const [reason, setReason] = useState('');
  const [postLoading, setPostLoading] = useState(false);
  const [rejectReasonShow, setRejectReasonShow] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    search_content: '',
    status: '',
  });
  const location = useLocation();
  const nav = useNavigate();
  const goBack = () => {
    nav('../flats');
  };

  useEffect(() => {
    if (id) getTalentRoomDetail(id);
  }, [id]);
  useEffect(() => {
    if (talentRoomDetail?.id) {
      const valleyId = localStorage.getItem('default_valley_id');

      Number(valleyId) === 12
        ? setApplyStatus(talentRoomDetail.apply_status)
        : setApplyStatus(talentRoomDetail.department_audit_status);
    }
  }, [talentRoomDetail]);
  const handleStatusChange = (status: number, reason: string = '') => {
    setPostLoading(true);

    postTalentRoom(id, { apply_status: status, reason })
      .then(() => {
        message.success('修改成功');

        if (status === 2) return nav('../flats');
        getTalentRoomDetail(id);
      })
      .finally(() => {
        setPostLoading(false);
      });
  };
  const handleReject = () => {
    if (!reason)  return message.error('请输入驳回原因');
    handleStatusChange(2, reason);
  };
  return (
    <div className="page-flat-talent-detail-wrapper">
      <div className="header-back" onClick={goBack}>
        <ArrowLeftOutlined style={{ marginRight: 14, color: '#000000' }} /> 返回
      </div>
      <Spin tip="加载中" spinning={talentRoomDetail.loading}>
        <Descriptions
          title={
            <div>
              <span style={{ fontSize: 28 }}>{talentRoomDetail.name}</span>
              <span style={{ fontSize: 14, marginLeft: 16 }}>{talentRoomDetail.company_name}</span>
            </div>
          }
          column={2}
          extra={
            <Space size={16}>
              {applyStatus === 1 && (
                <>
                  <Button loading={postLoading} danger onClick={() => setRejectReasonShow(true)}>
                    驳回
                  </Button>
                  <Button
                    loading={postLoading}
                    type="primary"
                    onClick={() => handleStatusChange(3)}>
                    通过
                  </Button>
                </>
              )}
              {applyStatus === 3 && (
                <Button loading={postLoading} type="primary" disabled>
                  已通过
                </Button>
              )}
            </Space>
          }>
          <Descriptions.Item label="证件类型">
            {talentRoomDetail.id_card_type
              ? idCardTypeMap[talentRoomDetail.id_card_type] || '-'
              : '无'}
          </Descriptions.Item>
          <Descriptions.Item label="证件号码">
            {talentRoomDetail.id_card_number || '无'}
          </Descriptions.Item>
          <Descriptions.Item label="出生年月">
            {talentRoomDetail.birth ? talentRoomDetail.birth : '无'}
          </Descriptions.Item>
          <Descriptions.Item label="性别">{talentRoomDetail.sex || '无'}</Descriptions.Item>
          {/* <Descriptions.Item label="企业联系电话">
            {talentRoomDetail.company_phone_number || '无'}
          </Descriptions.Item> */}
          <Descriptions.Item label="联系电话" span={2}>
            {talentRoomDetail.phone || '无'}
          </Descriptions.Item>
          <Descriptions.Item label="人才类型">
            {talentRoomDetail.apply_type ? talentType[talentRoomDetail.apply_type - 1] : '无'}
          </Descriptions.Item>

          <Descriptions.Item label="附件" span={2}>
            {talentRoomDetail.annex_url ? (
              <a target="_blank" href={talentRoomDetail.annex_url}>
                查看附件
              </a>
            ) : (
              '无'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="备注" span={2}>
            {talentRoomDetail.remark || '无'}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
      <Modal
        title="驳回原因"
        open={rejectReasonShow}
        onOk={handleReject}
        okButtonProps={{
          loading: postLoading,
        }}
        onCancel={() => setRejectReasonShow(false)}>
        <Input
          value={reason}
          onChange={({ target }: any) => {
            setReason(target.value);
          }}
        />
      </Modal>
    </div>
  );
};

export default FlatTalentDetail;
