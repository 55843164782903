export const sex = ['男', '女'];

export const experiences = ['<1年', '1-3年', '3-5年', '5-10年', '>10年'];
export const degrees = ['初中', '中专', '高中', '大专', '本科', '硕士', '博士'];
export const age = ['20岁以下', '20-30岁', '30-40岁', '40岁以上'];
export const employmentType = ['全职', '兼职', '实习'];
export const salaryGrade = [
	'3k以下',
	'3-5k',
	'5-10k',
	'10-15k',
	'15-20k',
	'20-30k',
	'30-50k',
	'50k以上',
];
export const experiencesFilter = [
	{ label: '<1年', value: '0,1' },
	{ label: '1-3年', value: '1,3' },
	{ label: '3-5年', value: '3,5' },
	{ label: '5-10年', value: '5,10' },
	{ label: '>10年', value: '10,99' },
];
export const salaryFilter = [
	{ label: '3k以下', value: '0,3000' },
	{ label: '3-5k', value: '3000,5000' },
	{ label: '5-10k', value: '5000,10000' },
	{ label: '15-20k', value: '15000,20000' },
	{ label: '20-30k', value: '20000,30000' },
	{ label: '30-50k', value: '30000,50000' },
	{ label: '50k以上', value: '50000,1000000' },
];
export const ageFilter = [
	{ label: '20岁以下', value: '0,20' },
	{ label: '20-30岁', value: '20,30' },
	{ label: '30-40岁', value: '30,40' },
	{ label: '40岁以上', value: '40,100' },

];


const activityCompanySelectOptions = {
	"input": "单行输入框",
	"textarea": "多行输入框",
	"radioSelect": "单选选择框",
	"checkboxSelect": "多选选择框",
	"uploadFile": "上传"
}


const activityJobSeekersOptions = {
	input: "单行输入框",
	number: "数字输入框",
	textarea: "多行输入框",
	picker: "单选选择框",
	multiSelect: "多选选择框",
	upload: "上传文件",
	year: "年份选择",
	idcard: "身份证"
}

export const activityCompanyTableCol = [
	{
		title: "标题",
		key: "title",
		dataIndex: "title"
	},
	{
		title: "必填",
		key: "required",
		dataIndex: "required",
		render(value, record, index) {
			return value ? "是" : "否"
		},
	},
	{
		title: "类型",
		key: "type",
		dataIndex: "type",
		render(value, record, index) {
			return activityCompanySelectOptions[(value)]
		},
	},
	{
		title: '限制条件/选项',
		key: 'limit',
		ellipsis: true,
		dataIndex: 'limit',
		render(value, { type }) {

			// return   Array.isArray(value) ? value.join(',') : value;
			if (type === 'input' || type === 'textarea') return `最多 ${value} 个字`
			if (type === 'radioSelect' || type === 'checkboxSelect') return Array.isArray(value) ? value.join('、') : value
			else return value
		},
	},
]


export const activityExhibitionAreaCol = [
	{
		title: "名称",
		dataIndex: "name"
	},
	{
		title: "编号",
		dataIndex: "internal_code"
	},
	{
		title: "企业上限",
		dataIndex: "company_num_limit"
	}

]


export const activityJobSeekersCol = [
	{
		title: "标题",
		dataIndex: "title"
	},
	{
		title: "必填",
		dataIndex: "required",
		render(value, record, index) {

			return <div>{value ? "是" : "否"}</div>
		},
	},
	{
		title: "类型",
		dataIndex: "type",
		render(v) {
			return <div>{activityJobSeekersOptions[v]}</div>
		}
	},
	{
		title: "条件",
		dataIndex: "options",
		render(v) {
			return <div>{v ? v.join(",") : "无"}</div>
		}
	},
	{
		title: "操作"
	}
]


export const companyScaleType = [
	"微型(10人以下)",
	"小型(10-50人)",
	"中型(50-100人)",
	"大型(100 - 500人)",
	"超大型(500人以上)"
]
//求职类型
export const jobHuntingStatus = [
	"待定",
	"在校 - 想找实习",
	"在校 - 想找全职",
	"离职 - 随时到岗",
	"在职 - 月内到岗",
	"在职 - 考虑机会",
]
//简历来源
export const sourceFrom = [
	"个人上传",
	"企业解析",
	"活动来源",
	"GK",
	"其他网址",
	"园区上传",
	"企业移动"
]

//薪资单位
export const salaryUnit = [
	"元/时",
	"元/天",
	"元/月",
	"元/年"
]

export const industryData = [
	{
		"label": "互联网/IT/电子/通信",
		"value": "互联网/IT/电子/通信",
		children: [{ "label": "电子商务", "value": "电子商务" }, { "label": "游戏", "value": "游戏" }, {
			"label": "媒体",
			"value": "媒体"
		}, { "label": "广告营销", "value": "广告营销" }, { "label": "数据服务", "value": "数据服务" }, {
			"label": "医疗健康",
			"value": "医疗健康"
		}, { "label": "生活服务", "value": "生活服务" }, { "label": "O2O", "value": "O2O" }, {
			"label": "旅游",
			"value": "旅游"
		}, { "label": "分类信息", "value": "分类信息" }, {
			"label": "音乐/视频/阅读",
			"value": "音乐/视频/阅读"
		}, { "label": "在线教育", "value": "在线教育" }, { "label": "社交网络", "value": "社交网络" }, {
			"label": "人力资源服务",
			"value": "人力资源服务"
		}, { "label": "企业服务", "value": "企业服务" }, { "label": "信息安全", "value": "信息安全" }, {
			"label": "新零售",
			"value": "新零售"
		}, { "label": "智能硬件", "value": "智能硬件" }, { "label": "移动互联网", "value": "移动互联网" }, {
			"label": "互联网",
			"value": "互联网"
		}, { "label": "计算机软件", "value": "计算机软件" }, {
			"label": "计算机硬件",
			"value": "计算机硬件"
		}, { "label": "计算机服务", "value": "计算机服务" }, {
			"label": "通信/网络设备",
			"value": "通信/网络设备"
		}, { "label": "运营商/增值服务", "value": "运营商/增值服务" }, {
			"label": "电子/半导体/集成电路",
			"value": "电子/半导体/集成电路"
		}]
	}, {
		"label": "区块链",
		"value": "区块链",
		"children": [{ "label": "区块链/金融", "value": "区块链/金融" }, {
			"label": "区块链/医疗",
			"value": "区块链/医疗"
		}, { "label": "区块链/其他", "value": "区块链/其他" }]
	}, {
		"label": "广告/传媒/文化/体育",
		"value": "广告/传媒/文化/体育",
		"children": [{ "label": "广告/公关/会展", "value": "广告/公关/会展" }, {
			"label": "新闻/出版",
			"value": "新闻/出版"
		}, { "label": "广播/影视", "value": "广播/影视" }, { "label": "文化/体育/娱乐", "value": "文化/体育/娱乐" }]
	}, {
		"label": "金融",
		"value": "金融",
		"children": [{ "label": "银行", "value": "银行" }, { "label": "保险", "value": "保险" }, {
			"label": "证券/期货",
			"value": "证券/期货"
		}, { "label": "基金", "value": "基金" }, { "label": "信托", "value": "信托" }, {
			"label": "互联网金融",
			"value": "互联网金融"
		}, { "label": "投资/融资", "value": "投资/融资" }, {
			"label": "租赁/拍卖/典当/担保",
			"value": "租赁/拍卖/典当/担保"
		}]
	}, {
		"label": "教育培训",
		"value": "教育培训",
		"children": [{ "label": "学前教育", "value": "学前教育" }, { "label": "院校", "value": "院校" }, {
			"label": "培训机构",
			"value": "培训机构"
		}, { "label": "学术/科研", "value": "学术/科研" }]
	}, {
		"label": "制药/医疗",
		"value": "制药/医疗",
		"children": [{ "label": "制药", "value": "制药" }, {
			"label": "医疗/护理/卫生",
			"value": "医疗/护理/卫生"
		}, { "label": "医疗设备/器械", "value": "医疗设备/器械" }]
	}, {
		"label": "交通/物流/贸易/零售",
		"value": "交通/物流/贸易/零售",
		"children": [{ "label": "交通/运输", "value": "交通/运输" }, {
			"label": "物流/仓储",
			"value": "物流/仓储"
		}, { "label": "批发/零售", "value": "批发/零售" }, { "label": "贸易/进出口", "value": "贸易/进出口" }]
	}, {
		"label": "专业服务",
		"value": "专业服务",
		"children": [{ "label": "咨询", "value": "咨询" }, { "label": "法律", "value": "法律" }, {
			"label": "翻译",
			"value": "翻译"
		}, { "label": "人力资源服务", "value": "人力资源服务" }, {
			"label": "财务/审计/税务",
			"value": "财务/审计/税务"
		}, { "label": "检测/认证", "value": "检测/认证" }, {
			"label": "专利/商标/知识产权",
			"value": "专利/商标/知识产权"
		}, { "label": "其他专业服务", "value": "其他专业服务" }]
	}, {
		"label": "汽车",
		"value": "汽车",
		"children": [{ "label": "汽车生产", "value": "汽车生产" }, {
			"label": "汽车零部件",
			"value": "汽车零部件"
		}, { "label": "4S店/后市场", "value": "4S店/后市场" }]
	}, {
		"label": "制造业",
		"value": "制造业",
		"children": [{ "label": "纺织服装业", "value": "纺织服装业" }, {
			"label": "机械设备/机电/重工",
			"value": "机械设备/机电/重工"
		}, { "label": "仪器仪表/工业自动化", "value": "仪器仪表/工业自动化" }, {
			"label": "原材料及加工/模具",
			"value": "原材料及加工/模具"
		}, { "label": "印刷/包装/造纸", "value": "印刷/包装/造纸" }, {
			"label": "船舶/航天/航天",
			"value": "船舶/航天/航天"
		}]
	}, {
		"label": "消费品",
		"value": "消费品",
		"children": [{ "label": "食物/饮料/烟酒", "value": "食物/饮料/烟酒" }, {
			"label": "日化",
			"value": "日化"
		}, { "label": "服装/皮革", "value": "服装/皮革" }, {
			"label": "家具/家电/家居",
			"value": "家具/家电/家居"
		}, { "label": "玩具/礼品", "value": "玩具/礼品" }, {
			"label": "珠宝/首饰",
			"value": "珠宝/首饰"
		}, { "label": "工艺品/收藏品", "value": "工艺品/收藏品" }, { "label": "办公用品及设备", "value": "办公用品及设备" }]
	}, {
		"label": "服务业",
		"value": "服务业",
		"children": [{ "label": "饮料", "value": "饮料" }, { "label": "酒店", "value": "酒店" }, {
			"label": "旅游",
			"value": "旅游"
		}, { "label": "美容/美发", "value": "美容/美发" }, {
			"label": "婚庆/摄影",
			"value": "婚庆/摄影"
		}, { "label": "其他服务行业", "value": "其他服务行业" }]
	}, {
		"label": "能源/化工/环保",
		"value": "能源/化工/环保",
		"children": [{ "label": "石油/石化", "value": "石油/石化" }, {
			"label": "化工",
			"value": "化工"
		}, { "label": "矿产/地质", "value": "矿产/地质" }, {
			"label": "采掘/冶炼",
			"value": "采掘/冶炼"
		}, { "label": "电力/热力/燃气/水利", "value": "电力/热力/燃气/水利" }, {
			"label": "新能源",
			"value": "新能源"
		}, { "label": "环保", "value": "环保" }]
	}, {
		"label": "房地产/建筑",
		"value": "房地产/建筑",
		"children": [{ "label": "房地产/建筑", "value": "房地产/建筑" }, {
			"label": "房地产开发",
			"value": "房地产开发"
		}, { "label": "工程施工", "value": "工程施工" }, { "label": "建筑设计", "value": "建筑设计" }, {
			"label": "装饰装修",
			"value": "装饰装修"
		}, { "label": "建材", "value": "建材" }, { "label": "房地产/中介", "value": "房地产/中介" }, {
			"label": "物业服务",
			"value": "物业服务"
		}, { label: "其他", value: "其他" }]
	}, {
		"label": "政府/非盈利/其他",
		"value": "政府/非盈利/其他",
		"children": [{ "label": "政府/公共事业", "value": "政府/公共事业" }, {
			"label": "非营利机构",
			"value": "非营利机构"
		}, { "label": "农/林/牧/渔", "value": "农/林/牧/渔" }, { "label": "其他行业", "value": "其他行业" }]
	},
	// { "label": "科技金融", "value": "科技金融", children: [{ "label": "科技金融", "value": "科技金融" }] },
	// { "label": "公共事业", "value": "公共事业", children: [{ "label": "公共事业", "value": "公共事业" }] },
	// { "label": "现代综合", "value": "现代综合", children: [{ "label": "现代综合", "value": "现代综合" }] },
	// { "label": "城市交通", "value": "城市交通", children: [{ "label": "城市交通", "value": "城市交通" }] },
]
export const treeIndustry = [

	{
		label: '技术',
		value: '技术',
		children: [
			{ value: '后端开发', label: '后端开发' },
			{ value: '测试', label: '测试' },
			{ value: '移动开发', label: '移动开发' },
			{ value: '前端开发', label: '前端开发' },
			{ value: '运维/技术支持', label: '运维/技术支持' },
			{ value: '项目管理', label: '项目管理' },
			{ value: '数据', label: '数据' },
			{ value: '硬件开发', label: '硬件开发' },
			{ value: '人工智能', label: '人工智能' },
			{ value: '高端技术职位', label: '高端技术职位' },
			{ value: '电子/半导体', label: '电子/半导体' },
			{ value: '软件销售支持', label: '软件销售支持' },
			{ value: '其他技术职位', label: '其他技术职位' },
			{ value: '通信', label: '通信' },
		],
	},
	{
		label: '运营',
		value: '运营',
		children: [
			{ value: '客服', label: '客服' },
			{ value: '高端运营职位', label: '高端运营职位' },
			{ value: '编辑', label: '编辑' },
			{ value: '其他运营职位', label: '其他运营职位' },
		],
	},
	{
		label: '销售',
		value: '销售',
		children: [
			{ value: '销售管理', label: '销售管理' },
			{ value: '其他销售职位', label: '其他销售职位' },
		],
	},
	{
		label: '人事/财务/行政',
		value: '人事/财务/行政',
		children: [
			{ value: '行政', label: '行政' },
			{ value: '人力资源', label: '人力资源' },
			{ value: '财务', label: '财务' },
			{ value: '法务', label: '法务' },
			{ value: '其他职能职位', label: '其他职能职位' },
		],
	},
	{
		label: '高级管理',
		value: '高级管理',
		children: [{
			value: '高级管理职位', label: '高级管理职位'
		}]
	},
	{
		label: '设计',
		value: '设计',
		children: [
			{ value: '视觉设计', label: '视觉设计' },
			{ value: '非视觉设计', label: '非视觉设计' },
			{ value: '高端设计职位', label: '高端设计职位' },
			{ value: '交互设计', label: '交互设计' },
			{ value: '用户研究', label: '用户研究' },
			{ value: '其他设计职位', label: '其他设计职位' },
		],
	},
	{
		label: '市场',
		value: '市场',
		children: [
			{ value: '市场/营销', label: '市场/营销' },
			{ value: '高端市场职位', label: '高端市场职位' },
			{ value: '其他市场职位', label: '其他市场职位' },
		],
	},
	{
		label: '金融',
		value: '金融',
		children: [
			{ value: '互联网金融', label: '互联网金融' },
			{ value: '投融资', label: '投融资' },
			{ value: '税务审计', label: '税务审计' },
			{ value: '银行', label: '银行' },
			{ value: '风控', label: '风控' },
			{ value: '证券', label: '证券' },
			{ value: '保险', label: '保险' },
			{ value: '其他金融职位', label: '其他金融职位' },
		],
	},
	{
		label: '传媒',
		value: '传媒',
		children: [
			{ value: '影视媒体', label: '影视媒体' },
			{ value: '采编/写作/出版', label: '采编/写作/出版' },
			{ value: '广告', label: '广告' },
			{ value: '公关媒介', label: '公关媒介' },
			{ value: '会务会展', label: '会务会展' },
			{ value: '其他传媒职位', label: '其他传媒职位' },
		],
	},
	{
		label: '教育培训',
		value: '教育培训',
		children: [
			{ value: '教师', label: '教师' },
			{ value: '教育行政', label: '教育行政' },
			{ value: '教练', label: '教练' },
			{ value: '教育产品研发', label: '教育产品研发' },
			{ value: '招生', label: '招生' },
			{ value: '职业培训', label: '职业培训' },
			{ value: '其他教育培训职位', label: '其他教育培训职位' },
			{ value: 'IT培训', label: 'IT培训' },
		],
	},
	{
		label: '产品',
		value: '产品',
		children: [
			{ value: '产品经理', label: '产品经理' },
			{ value: '高端产品职位', label: '高端产品职位' },
			{ value: '其他产品职位', label: '其他产品职位' },
		],
	},
	{
		label: '房地产/建筑',
		value: '房地产/建筑',
		children: [
			{ value: '设计装修与市政建设', label: '设计装修与市政建设' },
			{ value: '物业管理', label: '物业管理' },
			{ value: '房地产规划开发', label: '房地产规划开发' },
			{ value: '房地产经纪', label: '房地产经纪' },
			{ value: '高端房地产职位', label: '高端房地产职位' },
			{ value: '其他房地产职位', label: '其他房地产职位' },
		],
	},
	{
		label: '采购/贸易',
		value: '采购/贸易',
		children: [
			{ value: '采购', label: '采购' },
			{ value: '进出口贸易', label: '进出口贸易' },
			{ value: '其他采购/贸易职位', label: '其他采购/贸易职位' },
		],
	},
	{
		label: '服务业',
		value: '服务业',
		children: [
			{ value: '餐饮', label: '餐饮' },
			{ value: '零售', label: '零售' },
			{ value: '酒店', label: '酒店' },
			{ value: '美容/健身', label: '美容/健身' },
			{ value: '安保/家政', label: '安保/家政' },
			{ value: '其他服务业职位', label: '其他服务业职位' },
		],
	},
	{
		label: '供应链/物流',
		value: '供应链/物流',
		children: [
			{ value: '物流', label: '物流' },
			{ value: '仓储', label: '仓储' },
			{ value: '高端供应链职位', label: '高端供应链职位' },
			{ value: '运输', label: '运输' },
			{ value: '其他供应链职位', label: '其他供应链职位' },
		],
	},
	{
		label: '医疗健康',
		value: '医疗健康',
		children: [
			{ value: '医生/医技', label: '医生/医技' },
			{ value: '护士/护理', label: '护士/护理' },
			{ value: '市场营销/媒体', label: '市场营销/媒体' },
			{ value: '生物制药', label: '生物制药' },
			{ value: '健康整形', label: '健康整形' },
			{ value: '药店', label: '药店' },
			{ value: '医疗器械', label: '医疗器械' },
			{ value: '其他医疗健康类职位', label: '其他医疗健康类职位' },
		],
	},
	{
		label: '生产制造',
		value: '生产制造',
		children: [
			{ value: '技工/普工', label: '技工/普工' },
			{ value: '服装/纺织/皮革', label: '服装/纺织/皮革' },
			{ value: '机械设计/制造', label: '机械设计/制造' },
			{ value: '质量安全', label: '质量安全' },
			{ value: '生产营运', label: '生产营运' },
			{ value: '化工', label: '化工' },
			{ value: '其他生产制造职位', label: '其他生产制造职位' },
		],
	},
	{
		label: '咨询/翻译/法律',
		value: '咨询/翻译/法律',
		children: [
			{ value: '翻译', label: '翻译' },
			{ value: '律师', label: '律师' },
			{ value: '咨询/调研', label: '咨询/调研' },
			{ value: '高端咨询类职位', label: '高端咨询类职位' },
			{ value: '其他咨询类职位', label: '其他咨询类职位' },
		],
	},
	{
		label: '汽车',
		value: '汽车',
		children: [
			{ value: '汽车销售与服务', label: '汽车销售与服务' },
			{ value: '汽车设计与研发', label: '汽车设计与研发' },
			{ value: '汽车生产与制造', label: '汽车生产与制造' },
			{ value: '其他汽车职位', label: '其他汽车职位' },
		],
	},
	{
		label: '旅游',
		value: '旅游',
		children: [
			{ value: '旅游服务', label: '旅游服务' },
			{ value: '旅游产品开发/策划', label: '旅游产品开发/策划' },
			{ value: '其他旅游职位', label: '其他旅游职位' },
		],
	},
	{
		label: '实习生/管培生',
		value: '实习生/管培生',
		children: [
			{ value: '实习生/管培生/储备干部', label: '实习生/管培生/储备干部' },
			{ value: '其他实习/培训/储备职位', label: '其他实习/培训/储备职位' },
		],
	},
	{
		label: '其他',
		value: '其他',
		children: [{
			value: '其他职位类别', label: '其他职位类别',
		}]
	},
];
export const category = [{
	"label": "技术",
	"value": "技术",
	"children": [{
		"label": "后端开发",
		"value": "后端开发",
		"children": [{ "label": "Java", "value": "Java" }, { "label": "C++", "value": "C++" }, {
			"label": "PHP",
			"value": "PHP"
		}, { "label": "C", "value": "C" }, { "label": "C#", "value": "C#" }, {
			"label": ".NET",
			"value": ".NET"
		}, { "label": "Hadoop", "value": "Hadoop" }, { "label": "Python", "value": "Python" }, {
			"label": "Delphi",
			"value": "Delphi"
		}, { "label": "VB", "value": "VB" }, { "label": "Perl", "value": "Perl" }, {
			"label": "Ruby",
			"value": "Ruby"
		}, { "label": "Node.js", "value": "Node.js" }, { "label": "Golang", "value": "Golang" }, {
			"label": "Erlang",
			"value": "Erlang"
		}, { "label": "语音/视频/图形开发", "value": "语音/视频/图形开发" }, {
			"label": "数据采集",
			"value": "数据采集"
		}, { "label": "全栈工程师", "value": "全栈工程师" }, { "label": "GIS工程师", "value": "GIS工程师" }]
	}, {
		"label": "移动开发",
		"value": "移动开发",
		"children": [{ "label": "HTML5", "value": "HTML5" }, { "label": "Android", "value": "Android" }, {
			"label": "iOS",
			"value": "iOS"
		}, { "label": "移动web前端", "value": "移动web前端" }, {
			"label": "Flash开发",
			"value": "Flash开发"
		}, { "label": "JavaScript", "value": "JavaScript" }, { "label": "U3D", "value": "U3D" }, {
			"label": "COCOS2DX",
			"value": "COCOS2DX"
		}, { "label": "UE4", "value": "UE4" }]
	}, {
		"label": "测试",
		"value": "测试",
		"children": [{ "label": "测试工程师", "value": "测试工程师" }, {
			"label": "自动化测试",
			"value": "自动化测试"
		}, { "label": "功能测试", "value": "功能测试" }, { "label": "性能测试", "value": "性能测试" }, {
			"label": "测试开发",
			"value": "测试开发"
		}, { "label": "移动端测试", "value": "移动端测试" }, { "label": "游戏测试", "value": "游戏测试" }, {
			"label": "硬件测试",
			"value": "硬件测试"
		}, { "label": "软件测试", "value": "软件测试" }, { "label": "渗透测试", "value": "渗透测试" }]
	}, {
		"label": "运维/技术支持",
		"value": "运维/技术支持",
		"children": [{ "label": "运维工程师", "value": "运维工程师" }, {
			"label": "运维开发工程师",
			"value": "运维开发工程师"
		}, { "label": "网络工程师", "value": "网络工程师" }, {
			"label": "系统工程师",
			"value": "系统工程师"
		}, { "label": "IT技术支持", "value": "IT技术支持" }, {
			"label": "系统管理员",
			"value": "系统管理员"
		}, { "label": "网络安全", "value": "网络安全" }, { "label": "系统安全", "value": "系统安全" }, {
			"label": "DBA",
			"value": "DBA"
		}]
	}, {
		"label": "数据",
		"value": "数据",
		"children": [{ "label": "ETL工程师", "value": "ETL工程师" }, {
			"label": "数据仓库",
			"value": "数据仓库"
		}, { "label": "数据开发", "value": "数据开发" }, { "label": "数据挖掘", "value": "数据挖掘" }, {
			"label": "数据分析师",
			"value": "数据分析师"
		}, { "label": "数据架构师", "value": "数据架构师" }]
	}, {
		"label": "项目管理",
		"value": "项目管理",
		"children": [{ "label": "项目经理", "value": "项目经理" }, {
			"label": "项目主管",
			"value": "项目主管"
		}, { "label": "项目助理", "value": "项目助理" }, { "label": "项目专员", "value": "项目专员" }, {
			"label": "实施顾问",
			"value": "实施顾问"
		}, { "label": "实施工程师", "value": "实施工程师" }, {
			"label": "需求分析工程师",
			"value": "需求分析工程师"
		}, { "label": "硬件项目经理", "value": "硬件项目经理" }]
	}, {
		"label": "硬件开发",
		"value": "硬件开发",
		"children": [{ "label": "硬件工程师", "value": "硬件工程师" }, {
			"label": "嵌入式",
			"value": "嵌入式"
		}, { "label": "自动化", "value": "自动化" }, { "label": "单片机", "value": "单片机" }, {
			"label": "电路设计",
			"value": "电路设计"
		}, { "label": "驱动开发", "value": "驱动开发" }, { "label": "系统集成", "value": "系统集成" }, {
			"label": "FPGA开发",
			"value": "FPGA开发"
		}, { "label": "DSP开发", "value": "DSP开发" }, { "label": "ARM开发", "value": "ARM开发" }, {
			"label": "PCB工艺",
			"value": "PCB工艺"
		}, { "label": "射频工程师", "value": "射频工程师" }]
	}, {
		"label": "前端开发",
		"value": "前端开发",
		"children": [{ "label": "web前端", "value": "web前端" }, {
			"label": "JavaScript",
			"value": "JavaScript"
		}, { "label": "Flash开发", "value": "Flash开发" }, { "label": "HTML5", "value": "HTML5" }]
	}, {
		"label": "通信",
		"value": "通信",
		"children": [{ "label": "通信技术工程师", "value": "通信技术工程师" }, {
			"label": "通信研发工程师",
			"value": "通信研发工程师"
		}, { "label": "数据通信工程师", "value": "数据通信工程师" }, {
			"label": "移动通信工程师",
			"value": "移动通信工程师"
		}, { "label": "电信网络工程师", "value": "电信网络工程师" }, {
			"label": "电信交换工程师",
			"value": "电信交换工程师"
		}, { "label": "有线传输工程师", "value": "有线传输工程师" }, {
			"label": "无线/射频通信工程师",
			"value": "无线/射频通信工程师"
		}, { "label": "通信电源工程师", "value": "通信电源工程师" }, {
			"label": "通信标准化工程师",
			"value": "通信标准化工程师"
		}, { "label": "通信项目专员", "value": "通信项目专员" }, {
			"label": "通信项目经理",
			"value": "通信项目经理"
		}, { "label": "核心网工程师", "value": "核心网工程师" }, {
			"label": "通信测试工程师",
			"value": "通信测试工程师"
		}, { "label": "通信设备工程师", "value": "通信设备工程师" }, {
			"label": "光通信工程师",
			"value": "光通信工程师"
		}, { "label": "光传输工程师", "value": "光传输工程师" }, { "label": "光网络工程师", "value": "光网络工程师" }]
	}, {
		"label": "电子/半导体",
		"value": "电子/半导体",
		"children": [{ "label": "电气工程师", "value": "电气工程师" }, {
			"label": "电气设计工程师",
			"value": "电气设计工程师"
		}, { "label": "电子工程师", "value": "电子工程师" }, {
			"label": "集成电路IC设计",
			"value": "集成电路IC设计"
		}, { "label": "FAE", "value": "FAE" }, { "label": "IC验证工程师", "value": "IC验证工程师" }]
	}, {
		"label": "高端技术职位",
		"value": "高端技术职位",
		"children": [{ "label": "技术经理", "value": "技术经理" }, {
			"label": "技术总监",
			"value": "技术总监"
		}, { "label": "测试经理", "value": "测试经理" }, { "label": "架构师", "value": "架构师" }, {
			"label": "CTO",
			"value": "CTO"
		}, { "label": "运维总监", "value": "运维总监" }, { "label": "技术合伙人", "value": "技术合伙人" }]
	}, {
		"label": "人工智能",
		"value": "人工智能",
		"children": [{ "label": "数据挖掘", "value": "数据挖掘" }, {
			"label": "搜索算法",
			"value": "搜索算法"
		}, { "label": "自然语言处理", "value": "自然语言处理" }, {
			"label": "推荐算法",
			"value": "推荐算法"
		}, { "label": "算法工程师", "value": "算法工程师" }, {
			"label": "智能驾驶系统工程师",
			"value": "智能驾驶系统工程师"
		}, { "label": "反欺诈/风控算法", "value": "反欺诈/风控算法" }, {
			"label": "机器学习",
			"value": "机器学习"
		}, { "label": "深度学习", "value": "深度学习" }, { "label": "语音识别", "value": "语音识别" }, {
			"label": "图像识别",
			"value": "图像识别"
		}, { "label": "算法研究员", "value": "算法研究员" }]
	}, {
		"label": "销售技术支持",
		"value": "销售技术支持",
		"children": [{ "label": "售前技术支持", "value": "售前技术支持" }, {
			"label": "售后技术支持",
			"value": "售后技术支持"
		}]
	}, {
		"label": "其他技术职位",
		"value": "其他技术职位",
		"children": [{ "label": "其他技术职位", "value": "其他技术职位" }]
	}]
}, {
	"label": "运营",
	"value": "运营",
	"children": [{
		"label": "运营",
		"value": "运营",
		"children": [{ "label": "用户运营", "value": "用户运营" }, {
			"label": "产品运营",
			"value": "产品运营"
		}, { "label": "数据运营", "value": "数据运营" }, { "label": "内容运营", "value": "内容运营" }, {
			"label": "活动运营",
			"value": "活动运营"
		}, { "label": "商家运营", "value": "商家运营" }, { "label": "品类运营", "value": "品类运营" }, {
			"label": "游戏运营",
			"value": "游戏运营"
		}, { "label": "网站运营", "value": "网站运营" }, { "label": "新媒体运营", "value": "新媒体运营" }, {
			"label": "社区运营",
			"value": "社区运营"
		}, { "label": "微信运营", "value": "微信运营" }, { "label": "微博运营", "value": "微博运营" }, {
			"label": "策略运营",
			"value": "策略运营"
		}, { "label": "线下拓展运营", "value": "线下拓展运营" }, {
			"label": "电商运营",
			"value": "电商运营"
		}, { "label": "运营助理/专员", "value": "运营助理/专员" }, {
			"label": "内容审核",
			"value": "内容审核"
		}, { "label": "数据标注", "value": "数据标注" }, { "label": "直播运营", "value": "直播运营" }, {
			"label": "车辆运营",
			"value": "车辆运营"
		}, { "label": "跨境电商运营", "value": "跨境电商运营" }, { "label": "网店店长", "value": "网店店长" }]
	}, {
		"label": "编辑",
		"value": "编辑",
		"children": [{ "label": "副主编", "value": "副主编" }, {
			"label": "内容编辑",
			"value": "内容编辑"
		}, { "label": "文案策划", "value": "文案策划" }, { "label": "网站编辑", "value": "网站编辑" }, {
			"label": "采编",
			"value": "采编"
		}, { "label": "医学编辑", "value": "医学编辑" }]
	}, {
		"label": "客服",
		"value": "客服",
		"children": [{ "label": "售前客服", "value": "售前客服" }, {
			"label": "售后客服",
			"value": "售后客服"
		}, { "label": "网络客服", "value": "网络客服" }, { "label": "客服经理", "value": "客服经理" }, {
			"label": "客服专员",
			"value": "客服专员"
		}, { "label": "客服主管", "value": "客服主管" }, {
			"label": "电话客服",
			"value": "电话客服"
		}, { "label": "咨询热线/呼叫中心客服", "value": "咨询热线/呼叫中心客服" }]
	}, {
		"label": "高端运营职位",
		"value": "高端运营职位",
		"children": [{ "label": "主编", "value": "主编" }, { "label": "运营总监", "value": "运营总监" }, {
			"label": "COO",
			"value": "COO"
		}, { "label": "客服总监", "value": "客服总监" }, { "label": "运营经理/主管", "value": "运营经理/主管" }]
	}, {
		"label": "其他运营职位",
		"value": "其他运营职位",
		"children": [{ "label": "其他运营职位", "value": "其他运营职位" }]
	}]
}, {
	"label": "销售",
	"value": "销售",
	"children": [{
		"label": "销售行政/商务",
		"value": "销售行政/商务",
		"children": [{ "label": "商务专员", "value": "商务专员" }, {
			"label": "商务经理",
			"value": "商务经理"
		}, { "label": "销售助理", "value": "销售助理" }, { "label": "商务总监", "value": "商务总监" }, {
			"label": "销售运营",
			"value": "销售运营"
		}]
	}, {
		"label": "房地产销售/招商",
		"value": "房地产销售/招商",
		"children": [{ "label": "置业顾问", "value": "置业顾问" }, {
			"label": "地产中介",
			"value": "地产中介"
		}, { "label": "物业招商管理", "value": "物业招商管理" }, { "label": "房地产销售总监", "value": "房地产销售总监" }]
	}, {
		"label": "服务业销售",
		"value": "服务业销售",
		"children": [{ "label": "彩妆顾问", "value": "彩妆顾问" }, {
			"label": "美容顾问",
			"value": "美容顾问"
		}, { "label": "会籍顾问", "value": "会籍顾问" }, { "label": "珠宝销售", "value": "珠宝销售" }, {
			"label": "旅游顾问",
			"value": "旅游顾问"
		}, { "label": "瘦身顾问", "value": "瘦身顾问" }]
	}, {
		"label": "汽车销售",
		"value": "汽车销售",
		"children": [{ "label": "汽车销售", "value": "汽车销售" }, { "label": "汽车配件销售", "value": "汽车配件销售" }]
	}, {
		"label": "广告/会展销售",
		"value": "广告/会展销售",
		"children": [{ "label": "广告销售", "value": "广告销售" }, {
			"label": "会议活动销售",
			"value": "会议活动销售"
		}, { "label": "会展活动销售", "value": "会展活动销售" }, { "label": "媒介顾问", "value": "媒介顾问" }]
	}, {
		"label": "金融销售",
		"value": "金融销售",
		"children": [{ "label": "证券经纪人", "value": "证券经纪人" }, {
			"label": "信用卡销售",
			"value": "信用卡销售"
		}, { "label": "保险顾问", "value": "保险顾问" }, { "label": "理财顾问", "value": "理财顾问" }]
	}, {
		"label": "外贸销售",
		"value": "外贸销售",
		"children": [{ "label": "外贸经理", "value": "外贸经理" }, { "label": "外贸业务员", "value": "外贸业务员" }]
	}, {
		"label": "销售",
		"value": "销售",
		"children": [{ "label": "销售专员", "value": "销售专员" }, {
			"label": "客户代表",
			"value": "客户代表"
		}, { "label": "大客户代表", "value": "大客户代表" }, { "label": "BD经理", "value": "BD经理" }, {
			"label": "渠道销售",
			"value": "渠道销售"
		}, { "label": "代理商销售", "value": "代理商销售" }, { "label": "电话销售", "value": "电话销售" }, {
			"label": "销售顾问",
			"value": "销售顾问"
		}, { "label": "网络销售", "value": "网络销售" }, { "label": "销售工程师", "value": "销售工程师" }, {
			"label": "客户经理",
			"value": "客户经理"
		}]
	}, {
		"label": "课程销售",
		"value": "课程销售",
		"children": [{ "label": "课程顾问", "value": "课程顾问" }, {
			"label": "招生顾问",
			"value": "招生顾问"
		}, { "label": "留学顾问", "value": "留学顾问" }]
	}, {
		"label": "医疗销售",
		"value": "医疗销售",
		"children": [{ "label": "医疗器械销售", "value": "医疗器械销售" }, {
			"label": "医药代表",
			"value": "医药代表"
		}, { "label": "健康顾问", "value": "健康顾问" }, { "label": "医美咨询", "value": "医美咨询" }]
	}, {
		"label": "销售管理",
		"value": "销售管理",
		"children": [{ "label": "销售经理", "value": "销售经理" }, {
			"label": "销售总监",
			"value": "销售总监"
		}, { "label": "区域总监", "value": "区域总监" }, { "label": "城市经理", "value": "城市经理" }, {
			"label": "销售VP",
			"value": "销售VP"
		}, { "label": "团队经理", "value": "团队经理" }]
	}, {
		"label": "其他销售职位",
		"value": "其他销售职位",
		"children": [{ "label": "其他销售职位", "value": "其他销售职位" }]
	}]
}, {
	"label": "人事/财务/行政",
	"value": "人事/财务/行政",
	"children": [{
		"label": "人力资源",
		"value": "人力资源",
		"children": [{ "label": "人力资源主管", "value": "人力资源主管" }, {
			"label": "招聘",
			"value": "招聘"
		}, { "label": "HRBP", "value": "HRBP" }, {
			"label": "人力资源专员/助理",
			"value": "人力资源专员/助理"
		}, { "label": "培训", "value": "培训" }, { "label": "薪资福利", "value": "薪资福利" }, {
			"label": "绩效考核",
			"value": "绩效考核"
		}, { "label": "人力资源经理", "value": "人力资源经理" }, {
			"label": "人力资源VP/CHO",
			"value": "人力资源VP/CHO"
		}, { "label": "人力资源总监", "value": "人力资源总监" }, {
			"label": "员工关系",
			"value": "员工关系"
		}, { "label": "组织发展", "value": "组织发展" }]
	}, {
		"label": "行政",
		"value": "行政",
		"children": [{ "label": "行政专员/助理", "value": "行政专员/助理" }, {
			"label": "前台",
			"value": "前台"
		}, { "label": "行政主管", "value": "行政主管" }, { "label": "经理助理", "value": "经理助理" }, {
			"label": "后勤",
			"value": "后勤"
		}, { "label": "行政经理", "value": "行政经理" }, { "label": "行政总监", "value": "行政总监" }]
	}, {
		"label": "财务",
		"value": "财务",
		"children": [{ "label": "会计", "value": "会计" }, { "label": "出纳", "value": "出纳" }, {
			"label": "财务顾问",
			"value": "财务顾问"
		}, { "label": "结算", "value": "结算" }, { "label": "税务", "value": "税务" }, {
			"label": "审计",
			"value": "审计"
		}, { "label": "风控", "value": "风控" }, { "label": "成本", "value": "成本" }, {
			"label": "总账会计",
			"value": "总账会计"
		}, { "label": "财务经理", "value": "财务经理" }, { "label": "CFO", "value": "CFO" }, {
			"label": "财务总监",
			"value": "财务总监"
		}, { "label": "财务主管", "value": "财务主管" }]
	}, {
		"label": "法务",
		"value": "法务",
		"children": [{ "label": "法务专员/助理", "value": "法务专员/助理" }, {
			"label": "律师",
			"value": "律师"
		}, { "label": "法律顾问", "value": "法律顾问" }, { "label": "法务主管", "value": "法务主管" }, {
			"label": "法务经理",
			"value": "法务经理"
		}, { "label": "法务总监", "value": "法务总监" }]
	}, {
		"label": "其他职能职位",
		"value": "其他职能职位",
		"children": [{ "label": "其他职能职位", "value": "其他职能职位" }]
	}]
}, {
	"label": "高级管理",
	"value": "高级管理",
	"children": [{
		"label": "高级管理职位",
		"value": "高级管理职位",
		"children": [{ "label": "总裁/总经理/CEO", "value": "总裁/总经理/CEO" }, {
			"label": "副总裁/副总经理/VP",
			"value": "副总裁/副总经理/VP"
		}, { "label": "分公司/代表处负责人", "value": "分公司/代表处负责人" }, {
			"label": "区域负责人(辖多个分公司)",
			"value": "区域负责人(辖多个分公司)"
		}, { "label": "总助/CEO助理/董事长助理", "value": "总助/CEO助理/董事长助理" }, {
			"label": "合伙人",
			"value": "合伙人"
		}, { "label": "联合创始人", "value": "联合创始人" }, { "label": "董事会秘书", "value": "董事会秘书" }]
	}]
}, {
	"label": "设计",
	"value": "设计",
	"children": [{
		"label": "视觉/交互设计",
		"value": "视觉/交互设计",
		"children": [{ "label": "视觉设计师", "value": "视觉设计师" }, {
			"label": "网页设计师",
			"value": "网页设计师"
		}, { "label": "Flash设计师", "value": "Flash设计师" }, {
			"label": "APP设计师",
			"value": "APP设计师"
		}, { "label": "UI设计师", "value": "UI设计师" }, { "label": "平面设计", "value": "平面设计" }, {
			"label": "3D设计师",
			"value": "3D设计师"
		}, { "label": "广告设计", "value": "广告设计" }, {
			"label": "多媒体设计师",
			"value": "多媒体设计师"
		}, { "label": "原画师", "value": "原画师" }, { "label": "CAD设计/制图", "value": "CAD设计/制图" }, {
			"label": "美工",
			"value": "美工"
		}, { "label": "包装设计", "value": "包装设计" }, { "label": "设计师助理", "value": "设计师助理" }, {
			"label": "动画设计",
			"value": "动画设计"
		}, { "label": "插画师", "value": "插画师" }, { "label": "漫画师", "value": "漫画师" }, {
			"label": "人像修图师",
			"value": "人像修图师"
		}, { "label": "交互设计师", "value": "交互设计师" }]
	}, {
		"label": "游戏设计",
		"value": "游戏设计",
		"children": [{ "label": "游戏特效", "value": "游戏特效" }, {
			"label": "游戏界面设计师",
			"value": "游戏界面设计师"
		}, { "label": "游戏场景", "value": "游戏场景" }, { "label": "游戏角色", "value": "游戏角色" }, {
			"label": "游戏动作",
			"value": "游戏动作"
		}, { "label": "游戏数值策划", "value": "游戏数值策划" }]
	}, {
		"label": "用户研究",
		"value": "用户研究",
		"children": [{ "label": "数据分析师", "value": "数据分析师" }, {
			"label": "用户研究员",
			"value": "用户研究员"
		}, { "label": "UX设计师", "value": "UX设计师" }, {
			"label": "用户研究经理",
			"value": "用户研究经理"
		}, { "label": "用户研究总监", "value": "用户研究总监" }]
	}, {
		"label": "高端设计职位",
		"value": "高端设计职位",
		"children": [{ "label": "设计经理/主管", "value": "设计经理/主管" }, {
			"label": "设计总监",
			"value": "设计总监"
		}, { "label": "视觉设计总监", "value": "视觉设计总监" }]
	}, {
		"label": "非视觉设计",
		"value": "非视觉设计",
		"children": [{ "label": "展览/展示设计", "value": "展览/展示设计" }, {
			"label": "照明设计",
			"value": "照明设计"
		}, { "label": "服装/纺织设计", "value": "服装/纺织设计" }, {
			"label": "工业设计",
			"value": "工业设计"
		}, { "label": "橱柜设计", "value": "橱柜设计" }, { "label": "家具设计", "value": "家具设计" }, {
			"label": "家居设计",
			"value": "家居设计"
		}, { "label": "珠宝设计", "value": "珠宝设计" }, { "label": "室内设计", "value": "室内设计" }, {
			"label": "陈列设计",
			"value": "陈列设计"
		}]
	}, {
		"label": "其他设计职位",
		"value": "其他设计职位",
		"children": [{ "label": "其他设计职位", "value": "其他设计职位" }]
	}]
}, {
	"label": "市场",
	"value": "市场",
	"children": [{
		"label": "政府事务",
		"value": "政府事务",
		"children": [{ "label": "政府关系", "value": "政府关系" }, {
			"label": "政策研究",
			"value": "政策研究"
		}, { "label": "企业党建", "value": "企业党建" }]
	}, {
		"label": "市场/营销",
		"value": "市场/营销",
		"children": [{ "label": "网络推广", "value": "网络推广" }, {
			"label": "市场营销",
			"value": "市场营销"
		}, { "label": "市场策划", "value": "市场策划" }, { "label": "市场顾问", "value": "市场顾问" }, {
			"label": "市场推广",
			"value": "市场推广"
		}, { "label": "SEO", "value": "SEO" }, { "label": "SEM", "value": "SEM" }, {
			"label": "商务渠道",
			"value": "商务渠道"
		}, { "label": "商业数据分析", "value": "商业数据分析" }, {
			"label": "活动策划",
			"value": "活动策划"
		}, { "label": "网络营销", "value": "网络营销" }, { "label": "海外市场", "value": "海外市场" }, {
			"label": "APP推广",
			"value": "APP推广"
		}, { "label": "选址开发", "value": "选址开发" }, { "label": "游戏推广", "value": "游戏推广" }, {
			"label": "营销主管",
			"value": "营销主管"
		}]
	}, {
		"label": "公关媒介",
		"value": "公关媒介",
		"children": [{ "label": "媒介经理", "value": "媒介经理" }, {
			"label": "广告协调",
			"value": "广告协调"
		}, { "label": "品牌公关", "value": "品牌公关" }, { "label": "媒介专员", "value": "媒介专员" }, {
			"label": "活动策划执行",
			"value": "活动策划执行"
		}, { "label": "媒介策划", "value": "媒介策划" }]
	}, {
		"label": "会务会展",
		"value": "会务会展",
		"children": [{ "label": "会议活动策划", "value": "会议活动策划" }, {
			"label": "会议活动执行",
			"value": "会议活动执行"
		}, { "label": "会展活动策划", "value": "会展活动策划" }, { "label": "会展活动执行", "value": "会展活动执行" }]
	}, {
		"label": "广告",
		"value": "广告",
		"children": [{ "label": "广告/会展项目经理", "value": "广告/会展项目经理" }, {
			"label": "广告创意设计",
			"value": "广告创意设计"
		}, { "label": "美术指导", "value": "美术指导" }, { "label": "广告设计", "value": "广告设计" }, {
			"label": "策划经理",
			"value": "策划经理"
		}, { "label": "广告文案", "value": "广告文案" }, { "label": "广告制作", "value": "广告制作" }, {
			"label": "媒介投放",
			"value": "媒介投放"
		}, { "label": "媒介合作", "value": "媒介合作" }, { "label": "广告审核", "value": "广告审核" }]
	}, {
		"label": "高端市场职位",
		"value": "高端市场职位",
		"children": [{ "label": "市场总监", "value": "市场总监" }, { "label": "CMO", "value": "CMO" }, {
			"label": "公关总监",
			"value": "公关总监"
		}, { "label": "媒介总监", "value": "媒介总监" }, { "label": "创意总监", "value": "创意总监" }]
	}, {
		"label": "其他市场职位",
		"value": "其他市场职位",
		"children": [{ "label": "其他市场职位", "value": "其他市场职位" }]
	}]
}, {
	"label": "金融",
	"value": "金融",
	"children": [{
		"label": "投融资",
		"value": "投融资",
		"children": [{ "label": "投资经理", "value": "投资经理" }, {
			"label": "行业研究",
			"value": "行业研究"
		}, { "label": "资产管理", "value": "资产管理" }, { "label": "投资总监", "value": "投资总监" }, {
			"label": "投资VP",
			"value": "投资VP"
		}, { "label": "投资合伙人", "value": "投资合伙人" }, { "label": "融资", "value": "融资" }, {
			"label": "并购",
			"value": "并购"
		}, { "label": "投后管理", "value": "投后管理" }, {
			"label": "投资助理",
			"value": "投资助理"
		}, { "label": "其他投融资职位", "value": "其他投融资职位" }, { "label": "投资顾问", "value": "投资顾问" }]
	}, {
		"label": "风控",
		"value": "风控",
		"children": [{ "label": "风控", "value": "风控" }, { "label": "律师", "value": "律师" }, {
			"label": "资信评估",
			"value": "资信评估"
		}, { "label": "合规稽查", "value": "合规稽查" }]
	}, {
		"label": "税务审计",
		"value": "税务审计",
		"children": [{ "label": "审计", "value": "审计" }, { "label": "法务", "value": "法务" }, {
			"label": "会计",
			"value": "会计"
		}, { "label": "清算", "value": "清算" }]
	}, {
		"label": "银行",
		"value": "银行",
		"children": [{ "label": "分析师", "value": "分析师" }, { "label": "柜员", "value": "柜员" }, {
			"label": "商务渠道",
			"value": "商务渠道"
		}, { "label": "大堂经理", "value": "大堂经理" }, { "label": "客户经理", "value": "客户经理" }, {
			"label": "信贷管理",
			"value": "信贷管理"
		}, { "label": "风控", "value": "风控" }]
	}, {
		"label": "互联网金融",
		"value": "互联网金融",
		"children": [{ "label": "金融产品经理", "value": "金融产品经理" }, {
			"label": "风控",
			"value": "风控"
		}, { "label": "催收员", "value": "催收员" }, { "label": "分析师", "value": "分析师" }, {
			"label": "投资经理",
			"value": "投资经理"
		}, { "label": "审计", "value": "审计" }, { "label": "清算", "value": "清算" }]
	}, {
		"label": "保险",
		"value": "保险",
		"children": [{ "label": "保险精算师", "value": "保险精算师" }, { "label": "保险理赔", "value": "保险理赔" }]
	}, {
		"label": "证券",
		"value": "证券",
		"children": [{ "label": "证券分析师", "value": "证券分析师" }, { "label": "交易员", "value": "交易员" }]
	}, {
		"label": "其他金融职位",
		"value": "其他金融职位",
		"children": [{ "label": "其他金融职位", "value": "其他金融职位" }]
	}]
}, {
	"label": "传媒",
	"value": "传媒",
	"children": [{
		"label": "采编/写作/出版",
		"value": "采编/写作/出版",
		"children": [{ "label": "排版设计", "value": "排版设计" }, { "label": "记者", "value": "记者" }, {
			"label": "编辑",
			"value": "编辑"
		}, { "label": "采编", "value": "采编" }, { "label": "撰稿人", "value": "撰稿人" }, {
			"label": "出版发行",
			"value": "出版发行"
		}, { "label": "校对录入", "value": "校对录入" }, { "label": "总编", "value": "总编" }, {
			"label": "自媒体",
			"value": "自媒体"
		}]
	}, {
		"label": "公关媒介",
		"value": "公关媒介",
		"children": [{ "label": "媒介经理", "value": "媒介经理" }, {
			"label": "媒介专员",
			"value": "媒介专员"
		}, { "label": "广告协调", "value": "广告协调" }, { "label": "品牌公关", "value": "品牌公关" }, {
			"label": "活动策划执行",
			"value": "活动策划执行"
		}, { "label": "媒介策划", "value": "媒介策划" }]
	}, {
		"label": "广告",
		"value": "广告",
		"children": [{ "label": "广告/会展项目经理", "value": "广告/会展项目经理" }, {
			"label": "广告创意设计",
			"value": "广告创意设计"
		}, { "label": "美术指导", "value": "美术指导" }, { "label": "广告设计", "value": "广告设计" }, {
			"label": "策划经理",
			"value": "策划经理"
		}, { "label": "广告文案", "value": "广告文案" }, { "label": "广告制作", "value": "广告制作" }, {
			"label": "媒介投放",
			"value": "媒介投放"
		}, { "label": "媒介合作", "value": "媒介合作" }, { "label": "广告审核", "value": "广告审核" }]
	}, {
		"label": "影视媒体",
		"value": "影视媒体",
		"children": [{ "label": "艺人助理", "value": "艺人助理" }, {
			"label": "主持人/DJ",
			"value": "主持人/DJ"
		}, { "label": "主播助理", "value": "主播助理" }, { "label": "灯光师", "value": "灯光师" }, {
			"label": "剪辑师",
			"value": "剪辑师"
		}, { "label": "影视特效", "value": "影视特效" }, { "label": "导演/编导", "value": "导演/编导" }, {
			"label": "摄影/摄像",
			"value": "摄影/摄像"
		}, { "label": "视频编辑", "value": "视频编辑" }, { "label": "音频编辑", "value": "音频编辑" }, {
			"label": "经纪人",
			"value": "经纪人"
		}, { "label": "后期制作", "value": "后期制作" }, { "label": "影视发行", "value": "影视发行" }, {
			"label": "影视策划",
			"value": "影视策划"
		}, { "label": "主播", "value": "主播" }, {
			"label": "演员/配音/模特",
			"value": "演员/配音/模特"
		}, { "label": "化妆/造型/服装", "value": "化妆/造型/服装" }, {
			"label": "放映员",
			"value": "放映员"
		}, { "label": "录音/音效", "value": "录音/音效" }, { "label": "制片人", "value": "制片人" }, {
			"label": "编剧",
			"value": "编剧"
		}]
	}, {
		"label": "其他传媒职位",
		"value": "其他传媒职位",
		"children": [{ "label": "其他传媒职位", "value": "其他传媒职位" }]
	}]
}, {
	"label": "教育培训",
	"value": "教育培训",
	"children": [{
		"label": "教育产品研发",
		"value": "教育产品研发",
		"children": [{ "label": "课程设计", "value": "课程设计" }, {
			"label": "课程编辑",
			"value": "课程编辑"
		}, { "label": "培训研究", "value": "培训研究" }, { "label": "培训师", "value": "培训师" }, {
			"label": "培训策划",
			"value": "培训策划"
		}, { "label": "其他教育产品研发职位", "value": "其他教育产品研发职位" }]
	}, {
		"label": "教育行政",
		"value": "教育行政",
		"children": [{ "label": "园长/副园长", "value": "园长/副园长" }, {
			"label": "校长/副校长",
			"value": "校长/副校长"
		}, { "label": "教务管理", "value": "教务管理" }, {
			"label": "教学管理",
			"value": "教学管理"
		}, { "label": "班主任/辅导员", "value": "班主任/辅导员" }]
	}, {
		"label": "教师",
		"value": "教师",
		"children": [{ "label": "教师", "value": "教师" }, {
			"label": "日语教师",
			"value": "日语教师"
		}, { "label": "其他外语教师", "value": "其他外语教师" }, {
			"label": "语文教师",
			"value": "语文教师"
		}, { "label": "数学教师", "value": "数学教师" }, { "label": "物理教师", "value": "物理教师" }, {
			"label": "化学教师",
			"value": "化学教师"
		}, { "label": "生物教师", "value": "生物教师" }, { "label": "家教", "value": "家教" }, {
			"label": "托管老师",
			"value": "托管老师"
		}, { "label": "早教老师", "value": "早教老师" }, { "label": "助教", "value": "助教" }, {
			"label": "高中教师",
			"value": "高中教师"
		}, { "label": "初中教师", "value": "初中教师" }, { "label": "小学教师", "value": "小学教师" }, {
			"label": "幼教",
			"value": "幼教"
		}, { "label": "理科教师", "value": "理科教师" }, { "label": "文科教师", "value": "文科教师" }, {
			"label": "英语教师",
			"value": "英语教师"
		}, { "label": "音乐教师", "value": "音乐教师" }, { "label": "美术教师", "value": "美术教师" }, {
			"label": "体育教师",
			"value": "体育教师"
		}, { "label": "就业老师", "value": "就业老师" }]
	}, {
		"label": "IT培训",
		"value": "IT培训",
		"children": [{ "label": "JAVA培训讲师", "value": "JAVA培训讲师" }, {
			"label": "Android培训讲师",
			"value": "Android培训讲师"
		}, { "label": "iOS培训讲师", "value": "iOS培训讲师" }, {
			"label": "PHP培训讲师",
			"value": "PHP培训讲师"
		}, { "label": ".NET培训讲师", "value": ".NET培训讲师" }, {
			"label": "C++培训讲师",
			"value": "C++培训讲师"
		}, { "label": "Unity 3D培训讲师", "value": "Unity 3D培训讲师" }, {
			"label": "Web前端培训讲师",
			"value": "Web前端培训讲师"
		}, { "label": "软件测试培训讲师", "value": "软件测试培训讲师" }, {
			"label": "动漫培训讲师",
			"value": "动漫培训讲师"
		}, { "label": "UI设计培训讲师", "value": "UI设计培训讲师" }]
	}, {
		"label": "职业培训",
		"value": "职业培训",
		"children": [{ "label": "财会培训讲师", "value": "财会培训讲师" }, {
			"label": "HR培训讲师",
			"value": "HR培训讲师"
		}, { "label": "培训师", "value": "培训师" }, { "label": "拓展培训", "value": "拓展培训" }]
	}, {
		"label": "特长培训",
		"value": "特长培训",
		"children": [{ "label": "武术教练", "value": "武术教练" }, {
			"label": "轮滑教练",
			"value": "轮滑教练"
		}, { "label": "表演教师", "value": "表演教师" }, { "label": "机器人教师", "value": "机器人教师" }, {
			"label": "书法教师",
			"value": "书法教师"
		}, { "label": "钢琴教师", "value": "钢琴教师" }, { "label": "吉他教师", "value": "吉他教师" }, {
			"label": "古筝教师",
			"value": "古筝教师"
		}, { "label": "播音主持教师", "value": "播音主持教师" }, {
			"label": "乐高教师",
			"value": "乐高教师"
		}, { "label": "舞蹈老师", "value": "舞蹈老师" }, { "label": "瑜伽老师", "value": "瑜伽老师" }, {
			"label": "游泳教练",
			"value": "游泳教练"
		}, { "label": "健身教练", "value": "健身教练" }, {
			"label": "篮球/羽毛球教练",
			"value": "篮球/羽毛球教练"
		}, { "label": "跆拳道教练", "value": "跆拳道教练" }]
	}, {
		"label": "其他教育培训职位",
		"value": "其他教育培训职位",
		"children": [{ "label": "其他教育培训职位", "value": "其他教育培训职位" }]
	}]
}, {
	"label": "产品",
	"value": "产品",
	"children": [{
		"label": "产品经理",
		"value": "产品经理",
		"children": [{ "label": "产品经理", "value": "产品经理" }, {
			"label": "网页产品经理",
			"value": "网页产品经理"
		}, { "label": "移动产品经理", "value": "移动产品经理" }, {
			"label": "产品助理",
			"value": "产品助理"
		}, { "label": "数据产品经理", "value": "数据产品经理" }, {
			"label": "电商产品经理",
			"value": "电商产品经理"
		}, { "label": "游戏策划", "value": "游戏策划" }, { "label": "产品专员", "value": "产品专员" }, {
			"label": "硬件产品经理",
			"value": "硬件产品经理"
		}]
	}, {
		"label": "高端产品职位",
		"value": "高端产品职位",
		"children": [{ "label": "产品总监", "value": "产品总监" }, {
			"label": "游戏制作人",
			"value": "游戏制作人"
		}, { "label": "产品VP", "value": "产品VP" }]
	}, {
		"label": "其他产品职位",
		"value": "其他产品职位",
		"children": [{ "label": "其他产品职位", "value": "其他产品职位" }]
	}]
}, {
	"label": "房地产/建筑",
	"value": "房地产/建筑",
	"children": [{
		"label": "房地产规划开发",
		"value": "房地产规划开发",
		"children": [{ "label": "房地产策划", "value": "房地产策划" }, {
			"label": "地产项目管理",
			"value": "地产项目管理"
		}, { "label": "地产招投标", "value": "地产招投标" }, { "label": "房产评估师", "value": "房产评估师" }]
	}, {
		"label": "设计装修与市政建设",
		"value": "设计装修与市政建设",
		"children": [{ "label": "建筑工程师", "value": "建筑工程师" }, {
			"label": "建筑设计师",
			"value": "建筑设计师"
		}, { "label": "土木/土建/结构工程师", "value": "土木/土建/结构工程师" }, {
			"label": "室内设计",
			"value": "室内设计"
		}, { "label": "园林/景观设计", "value": "园林/景观设计" }, {
			"label": "城市规划设计",
			"value": "城市规划设计"
		}, { "label": "工程监理", "value": "工程监理" }, { "label": "工程造价", "value": "工程造价" }, {
			"label": "工程预算",
			"value": "工程预算"
		}, { "label": "资料员", "value": "资料员" }, {
			"label": "建筑施工现场管理",
			"value": "建筑施工现场管理"
		}, { "label": "弱电工程师", "value": "弱电工程师" }, {
			"label": "给排水工程师",
			"value": "给排水工程师"
		}, { "label": "暖通工程师", "value": "暖通工程师" }, {
			"label": "幕墙工程师",
			"value": "幕墙工程师"
		}, { "label": "软装设计师", "value": "软装设计师" }, { "label": "施工员", "value": "施工员" }, {
			"label": "测绘/测量",
			"value": "测绘/测量"
		}, { "label": "材料员", "value": "材料员" }, { "label": "BIM工程师", "value": "BIM工程师" }, {
			"label": "装修项目经理",
			"value": "装修项目经理"
		}, { "label": "建筑机电工程师", "value": "建筑机电工程师" }, {
			"label": "消防工程师",
			"value": "消防工程师"
		}, { "label": "施工安全员", "value": "施工安全员" }]
	}, {
		"label": "物业管理",
		"value": "物业管理",
		"children": [{ "label": "物业经理", "value": "物业经理" }, {
			"label": "物业维修",
			"value": "物业维修"
		}, { "label": "绿化工", "value": "绿化工" }, { "label": "物业管理员", "value": "物业管理员" }]
	}, {
		"label": "高端房地产职位",
		"value": "高端房地产职位",
		"children": [{ "label": "地产项目总监", "value": "地产项目总监" }, {
			"label": "地产策划总监",
			"value": "地产策划总监"
		}, { "label": "地产招投标总监", "value": "地产招投标总监" }]
	}, {
		"label": "其他房地产职位",
		"value": "其他房地产职位",
		"children": [{ "label": "其他房地产职位", "value": "其他房地产职位" }]
	}]
}, {
	"label": "采购/贸易",
	"value": "采购/贸易",
	"children": [{
		"label": "采购",
		"value": "采购",
		"children": [{ "label": "商品经理", "value": "商品经理" }, {
			"label": "供应商质量工程师",
			"value": "供应商质量工程师"
		}, { "label": "采购总监", "value": "采购总监" }, { "label": "采购经理", "value": "采购经理" }, {
			"label": "采购专员",
			"value": "采购专员"
		}, { "label": "买手", "value": "买手" }, { "label": "采购工程师", "value": "采购工程师" }, {
			"label": "采购主管",
			"value": "采购主管"
		}, { "label": "采购助理", "value": "采购助理" }]
	}, {
		"label": "进出口贸易",
		"value": "进出口贸易",
		"children": [{ "label": "贸易跟单", "value": "贸易跟单" }, {
			"label": "报关/报检员",
			"value": "报关/报检员"
		}, { "label": "单证员", "value": "单证员" }]
	}, {
		"label": "其他采购/贸易职位",
		"value": "其他采购/贸易职位",
		"children": [{ "label": "其他采购/贸易类职位", "value": "其他采购/贸易类职位" }]
	}]
}, {
	"label": "服务业",
	"value": "服务业",
	"children": [{
		"label": "餐饮",
		"value": "餐饮",
		"children": [{ "label": "后厨", "value": "后厨" }, { "label": "配菜打荷", "value": "配菜打荷" }, {
			"label": "茶艺师",
			"value": "茶艺师"
		}, { "label": "西点师", "value": "西点师" }, { "label": "餐饮学徒", "value": "餐饮学徒" }, {
			"label": "面点师",
			"value": "面点师"
		}, { "label": "行政总厨", "value": "行政总厨" }, { "label": "厨师长", "value": "厨师长" }, {
			"label": "传菜员",
			"value": "传菜员"
		}, { "label": "洗碗工", "value": "洗碗工" }, { "label": "凉菜厨师", "value": "凉菜厨师" }, {
			"label": "中餐厨师",
			"value": "中餐厨师"
		}, { "label": "西餐厨师", "value": "西餐厨师" }, { "label": "日料厨师", "value": "日料厨师" }, {
			"label": "烧烤师傅",
			"value": "烧烤师傅"
		}, { "label": "收银", "value": "收银" }, { "label": "服务员", "value": "服务员" }, {
			"label": "厨师",
			"value": "厨师"
		}, { "label": "咖啡师", "value": "咖啡师" }, { "label": "送餐员", "value": "送餐员" }, {
			"label": "餐饮店长",
			"value": "餐饮店长"
		}, { "label": "领班", "value": "领班" }]
	}, {
		"label": "酒店",
		"value": "酒店",
		"children": [{ "label": "礼仪/迎宾/接待", "value": "礼仪/迎宾/接待" }, {
			"label": "前厅经理",
			"value": "前厅经理"
		}, { "label": "客房经理", "value": "客房经理" }, { "label": "收银", "value": "收银" }, {
			"label": "酒店前台",
			"value": "酒店前台"
		}, { "label": "客房服务员", "value": "客房服务员" }, { "label": "酒店经理", "value": "酒店经理" }]
	}, {
		"label": "零售",
		"value": "零售",
		"children": [{ "label": "督导/巡店", "value": "督导/巡店" }, {
			"label": "陈列员",
			"value": "陈列员"
		}, { "label": "理货员", "value": "理货员" }, { "label": "防损员", "value": "防损员" }, {
			"label": "卖场经理",
			"value": "卖场经理"
		}, { "label": "促销员", "value": "促销员" }, { "label": "收银", "value": "收银" }, {
			"label": "导购",
			"value": "导购"
		}, { "label": "店员/营业员", "value": "店员/营业员" }, { "label": "门店店长", "value": "门店店长" }]
	}, {
		"label": "美容保健",
		"value": "美容保健",
		"children": [{ "label": "纹绣师", "value": "纹绣师" }, { "label": "美体师", "value": "美体师" }, {
			"label": "美发学徒",
			"value": "美发学徒"
		}, { "label": "美容店长", "value": "美容店长" }, { "label": "足疗师", "value": "足疗师" }, {
			"label": "按摩师",
			"value": "按摩师"
		}, { "label": "美睫师", "value": "美睫师" }, { "label": "发型师", "value": "发型师" }, {
			"label": "美甲师",
			"value": "美甲师"
		}, { "label": "化妆师", "value": "化妆师" }, { "label": "养发师", "value": "养发师" }, {
			"label": "美容师",
			"value": "美容师"
		}]
	}, {
		"label": "运动健身",
		"value": "运动健身",
		"children": [{ "label": "救生员", "value": "救生员" }, {
			"label": "瑜伽老师",
			"value": "瑜伽老师"
		}, { "label": "游泳教练", "value": "游泳教练" }, { "label": "美体教练", "value": "美体教练" }, {
			"label": "舞蹈老师",
			"value": "舞蹈老师"
		}, { "label": "健身教练", "value": "健身教练" }]
	}, {
		"label": "婚礼/花艺",
		"value": "婚礼/花艺",
		"children": [{ "label": "花艺师", "value": "花艺师" }, { "label": "婚礼策划", "value": "婚礼策划" }]
	}, {
		"label": "宠物服务",
		"value": "宠物服务",
		"children": [{ "label": "宠物美容", "value": "宠物美容" }, { "label": "宠物医生", "value": "宠物医生" }]
	}, {
		"label": "安保/家政/维修",
		"value": "安保/家政/维修",
		"children": [{ "label": "保安", "value": "保安" }, { "label": "保洁", "value": "保洁" }, {
			"label": "保姆",
			"value": "保姆"
		}, { "label": "月嫂", "value": "月嫂" }, { "label": "育婴师", "value": "育婴师" }, {
			"label": "护工",
			"value": "护工"
		}, { "label": "地铁安检", "value": "地铁安检" }, { "label": "手机维修", "value": "手机维修" }, {
			"label": "家电维修",
			"value": "家电维修"
		}, { "label": "保安经理", "value": "保安经理" }, { "label": "产后康复师", "value": "产后康复师" }, {
			"label": "钟点工",
			"value": "钟点工"
		}, { "label": "押运员", "value": "押运员" }, { "label": "消防中控员", "value": "消防中控员" }, {
			"label": "保洁经理",
			"value": "保洁经理"
		}]
	}, {
		"label": "其他服务业职位",
		"value": "其他服务业职位",
		"children": [{ "label": "其他服务业职位", "value": "其他服务业职位" }]
	}]
}, {
	"label": "供应链/物流",
	"value": "供应链/物流",
	"children": [{
		"label": "物流",
		"value": "物流",
		"children": [{ "label": "供应链专员", "value": "供应链专员" }, {
			"label": "供应链经理",
			"value": "供应链经理"
		}, { "label": "集装箱管理", "value": "集装箱管理" }, { "label": "物流专员", "value": "物流专员" }, {
			"label": "物流经理",
			"value": "物流经理"
		}, { "label": "物流运营", "value": "物流运营" }, { "label": "物流跟单", "value": "物流跟单" }, {
			"label": "调度员",
			"value": "调度员"
		}, { "label": "物流/仓储项目经理", "value": "物流/仓储项目经理" }, {
			"label": "货运代理专员",
			"value": "货运代理专员"
		}, { "label": "货运代理经理", "value": "货运代理经理" }, {
			"label": "水/空/陆运操作",
			"value": "水/空/陆运操作"
		}, { "label": "核销员", "value": "核销员" }]
	}, {
		"label": "仓储",
		"value": "仓储",
		"children": [{ "label": "仓库经理", "value": "仓库经理" }, {
			"label": "仓库管理员",
			"value": "仓库管理员"
		}, { "label": "仓库文员", "value": "仓库文员" }, { "label": "配/理/拣/发货", "value": "配/理/拣/发货" }]
	}, {
		"label": "交通/运输",
		"value": "交通/运输",
		"children": [{ "label": "商务司机", "value": "商务司机" }, {
			"label": "网约车司机",
			"value": "网约车司机"
		}, { "label": "代驾司机", "value": "代驾司机" }, { "label": "驾校教练", "value": "驾校教练" }, {
			"label": "货运司机",
			"value": "货运司机"
		}, { "label": "配送员", "value": "配送员" }, { "label": "快递员", "value": "快递员" }, {
			"label": "运输经理/主管",
			"value": "运输经理/主管"
		}]
	}, {
		"label": "高端供应链职位",
		"value": "高端供应链职位",
		"children": [{ "label": "供应链总监", "value": "供应链总监" }, { "label": "物流总监", "value": "物流总监" }]
	}, {
		"label": "其他供应链职位",
		"value": "其他供应链职位",
		"children": [{ "label": "其他供应链职位", "value": "其他供应链职位" }]
	}]
}, {
	"label": "医疗健康",
	"value": "医疗健康",
	"children": [{
		"label": "临床试验",
		"value": "临床试验",
		"children": [{ "label": "临床研究", "value": "临床研究" }, {
			"label": "临床协调",
			"value": "临床协调"
		}, { "label": "临床数据分析", "value": "临床数据分析" }, {
			"label": "临床项目经理",
			"value": "临床项目经理"
		}, { "label": "医学总监", "value": "医学总监" }]
	}, {
		"label": "医生/医技",
		"value": "医生/医技",
		"children": [{ "label": "医生", "value": "医生" }, {
			"label": "医生助理",
			"value": "医生助理"
		}, { "label": "医学影像/放射科医师", "value": "医学影像/放射科医师" }, {
			"label": "B超医生",
			"value": "B超医生"
		}, { "label": "内科医生", "value": "内科医生" }, { "label": "全科医生", "value": "全科医生" }, {
			"label": "中医",
			"value": "中医"
		}, { "label": "心理医生", "value": "心理医生" }, { "label": "药剂师", "value": "药剂师" }, {
			"label": "牙科医生",
			"value": "牙科医生"
		}, { "label": "康复治疗师", "value": "康复治疗师" }, { "label": "验光师", "value": "验光师" }, {
			"label": "检验科医师",
			"value": "检验科医师"
		}, { "label": "其他医生职位", "value": "其他医生职位" }]
	}, {
		"label": "护士/护理",
		"value": "护士/护理",
		"children": [{ "label": "护士长", "value": "护士长" }, { "label": "护士", "value": "护士" }, {
			"label": "导医",
			"value": "导医"
		}]
	}, {
		"label": "健康整形",
		"value": "健康整形",
		"children": [{ "label": "营养师", "value": "营养师" }, { "label": "整形师", "value": "整形师" }, {
			"label": "理疗师",
			"value": "理疗师"
		}, { "label": "针灸推拿", "value": "针灸推拿" }]
	}, {
		"label": "生物制药",
		"value": "生物制药",
		"children": [{ "label": "生物制药", "value": "生物制药" }, {
			"label": "药品注册",
			"value": "药品注册"
		}, { "label": "药品生产", "value": "药品生产" }, {
			"label": "医药项目经理",
			"value": "医药项目经理"
		}, { "label": "医药研发", "value": "医药研发" }]
	}, {
		"label": "医疗器械",
		"value": "医疗器械",
		"children": [{ "label": "医疗器械注册", "value": "医疗器械注册" }, {
			"label": "医疗器械生产/质量管理",
			"value": "医疗器械生产/质量管理"
		}, { "label": "医疗器械研发", "value": "医疗器械研发" }]
	}, {
		"label": "药店",
		"value": "药店",
		"children": [{ "label": "药店店长", "value": "药店店长" }, {
			"label": "执业药师/驻店药师",
			"value": "执业药师/驻店药师"
		}, { "label": "药店店员", "value": "药店店员" }]
	}, {
		"label": "其他医疗健康职位",
		"value": "其他医疗健康职位",
		"children": [{ "label": "其他医疗健康职位", "value": "其他医疗健康职位" }]
	}]
}, {
	"label": "生产制造",
	"value": "生产制造",
	"children": [{
		"label": "生产营运",
		"value": "生产营运",
		"children": [{ "label": "厂长", "value": "厂长" }, { "label": "生产总监", "value": "生产总监" }, {
			"label": "车间主任",
			"value": "车间主任"
		}, { "label": "生产组长/拉长", "value": "生产组长/拉长" }, {
			"label": "生产员",
			"value": "生产员"
		}, { "label": "生产设备管理", "value": "生产设备管理" }, {
			"label": "生产计划管理",
			"value": "生产计划管理"
		}, { "label": "生产跟单", "value": "生产跟单" }]
	}, {
		"label": "质量安全",
		"value": "质量安全",
		"children": [{ "label": "质检员", "value": "质检员" }, {
			"label": "质量管理/测试",
			"value": "质量管理/测试"
		}, { "label": "可靠度工程师", "value": "可靠度工程师" }, {
			"label": "故障分析师",
			"value": "故障分析师"
		}, { "label": "认证工程师", "value": "认证工程师" }, {
			"label": "体系工程师",
			"value": "体系工程师"
		}, { "label": "审核员", "value": "审核员" }, {
			"label": "生产安全员",
			"value": "生产安全员"
		}, { "label": "汽车质量工程师", "value": "汽车质量工程师" }]
	}, {
		"label": "新能源",
		"value": "新能源",
		"children": [{ "label": "电池工程师", "value": "电池工程师" }, {
			"label": "电机工程师",
			"value": "电机工程师"
		}, { "label": "线束设计", "value": "线束设计" }, { "label": "充电桩设计", "value": "充电桩设计" }]
	}, {
		"label": "汽车制造",
		"value": "汽车制造",
		"children": [{ "label": "汽车设计", "value": "汽车设计" }, {
			"label": "车身/造型设计",
			"value": "车身/造型设计"
		}, { "label": "底盘工程师", "value": "底盘工程师" }, {
			"label": "动力系统工程师",
			"value": "动力系统工程师"
		}, { "label": "汽车电子工程师", "value": "汽车电子工程师" }, {
			"label": "汽车零部件设计",
			"value": "汽车零部件设计"
		}, { "label": "汽车项目管理", "value": "汽车项目管理" }, {
			"label": "内外饰设计工程师",
			"value": "内外饰设计工程师"
		}, { "label": "总装工程师", "value": "总装工程师" }]
	}, {
		"label": "汽车服务",
		"value": "汽车服务",
		"children": [{ "label": "汽车服务顾问", "value": "汽车服务顾问" }, {
			"label": "汽车维修",
			"value": "汽车维修"
		}, { "label": "汽车美容", "value": "汽车美容" }, {
			"label": "汽车定损理赔",
			"value": "汽车定损理赔"
		}, { "label": "二手车评估师", "value": "二手车评估师" }, {
			"label": "4S店店长/维修站长",
			"value": "4S店店长/维修站长"
		}, { "label": "汽车改装工程师", "value": "汽车改装工程师" }]
	}, {
		"label": "机械设计/制造",
		"value": "机械设计/制造",
		"children": [{ "label": "热设计工程师", "value": "热设计工程师" }, {
			"label": "精益工程师",
			"value": "精益工程师"
		}, { "label": "机械工程师", "value": "机械工程师" }, {
			"label": "机械设计师",
			"value": "机械设计师"
		}, { "label": "机械设备工程师", "value": "机械设备工程师" }, {
			"label": "机械维修/保养",
			"value": "机械维修/保养"
		}, { "label": "机械制图", "value": "机械制图" }, {
			"label": "机械结构工程师",
			"value": "机械结构工程师"
		}, { "label": "工业工程师", "value": "工业工程师" }, {
			"label": "工艺/制程工程师",
			"value": "工艺/制程工程师"
		}, { "label": "材料工程师", "value": "材料工程师" }, {
			"label": "机电工程师",
			"value": "机电工程师"
		}, { "label": "CNC/数控", "value": "CNC/数控" }, {
			"label": "冲压工程师",
			"value": "冲压工程师"
		}, { "label": "夹具工程师", "value": "夹具工程师" }, {
			"label": "模具工程师",
			"value": "模具工程师"
		}, { "label": "焊接工程师", "value": "焊接工程师" }, {
			"label": "注塑工程师",
			"value": "注塑工程师"
		}, { "label": "铸造/锻造工程师", "value": "铸造/锻造工程师" }]
	}, {
		"label": "化工",
		"value": "化工",
		"children": [{ "label": "化工项目经理", "value": "化工项目经理" }, {
			"label": "化工工程师",
			"value": "化工工程师"
		}, { "label": "实验室技术员", "value": "实验室技术员" }, {
			"label": "化学分析",
			"value": "化学分析"
		}, { "label": "涂料研发", "value": "涂料研发" }, {
			"label": "化妆品研发",
			"value": "化妆品研发"
		}, { "label": "食品/饮料研发", "value": "食品/饮料研发" }]
	}, {
		"label": "服装/纺织/皮革",
		"value": "服装/纺织/皮革",
		"children": [{ "label": "服装/纺织设计", "value": "服装/纺织设计" }, {
			"label": "面料辅料开发",
			"value": "面料辅料开发"
		}, { "label": "打样/制版", "value": "打样/制版" }, { "label": "服装/纺织/皮革跟单", "value": "服装/纺织/皮革跟单" }]
	}, {
		"label": "技工/普工",
		"value": "技工/普工",
		"children": [{ "label": "普工/操作工", "value": "普工/操作工" }, {
			"label": "叉车工",
			"value": "叉车工"
		}, { "label": "铲车司机", "value": "铲车司机" }, { "label": "焊工", "value": "焊工" }, {
			"label": "氩弧焊工",
			"value": "氩弧焊工"
		}, { "label": "电工", "value": "电工" }, { "label": "木工", "value": "木工" }, {
			"label": "油漆工",
			"value": "油漆工"
		}, { "label": "车工", "value": "车工" }, { "label": "磨工", "value": "磨工" }, {
			"label": "铣工",
			"value": "铣工"
		}, { "label": "钳工", "value": "钳工" }, { "label": "钻工", "value": "钻工" }, {
			"label": "铆工",
			"value": "铆工"
		}, { "label": "钣金工", "value": "钣金工" }, { "label": "抛光工", "value": "抛光工" }, {
			"label": "机修工",
			"value": "机修工"
		}, { "label": "折弯工", "value": "折弯工" }, { "label": "电镀工", "value": "电镀工" }, {
			"label": "喷塑工",
			"value": "喷塑工"
		}, { "label": "注塑工", "value": "注塑工" }, { "label": "组装工", "value": "组装工" }, {
			"label": "包装工",
			"value": "包装工"
		}, { "label": "空调工", "value": "空调工" }, { "label": "电梯工", "value": "电梯工" }, {
			"label": "锅炉工",
			"value": "锅炉工"
		}, { "label": "学徒工", "value": "学徒工" }, { "label": "缝纫工", "value": "缝纫工" }, {
			"label": "搬运工",
			"value": "搬运工"
		}, { "label": "切割工", "value": "切割工" }, { "label": "样衣工", "value": "样衣工" }, {
			"label": "模具工",
			"value": "模具工"
		}]
	}, {
		"label": "其他生产制造职位",
		"value": "其他生产制造职位",
		"children": [{ "label": "其他生产制造职位", "value": "其他生产制造职位" }]
	}]
}, {
	"label": "咨询/翻译/法律",
	"value": "咨询/翻译/法律",
	"children": [{
		"label": "咨询/调研",
		"value": "咨询/调研",
		"children": [{ "label": "知识产权/专利/商标代理人", "value": "知识产权/专利/商标代理人" }, {
			"label": "心理咨询师",
			"value": "心理咨询师"
		}, { "label": "婚恋咨询师", "value": "婚恋咨询师" }, {
			"label": "企业管理咨询",
			"value": "企业管理咨询"
		}, { "label": "咨询总监", "value": "咨询总监" }, { "label": "数据分析师", "value": "数据分析师" }, {
			"label": "咨询经理",
			"value": "咨询经理"
		}, { "label": "财务咨询顾问", "value": "财务咨询顾问" }, {
			"label": "IT咨询顾问",
			"value": "IT咨询顾问"
		}, { "label": "人力资源咨询顾问", "value": "人力资源咨询顾问" }, {
			"label": "咨询项目管理",
			"value": "咨询项目管理"
		}, { "label": "战略咨询", "value": "战略咨询" }, { "label": "猎头顾问", "value": "猎头顾问" }, {
			"label": "市场调研",
			"value": "市场调研"
		}, { "label": "其他咨询顾问", "value": "其他咨询顾问" }, { "label": "规划咨询", "value": "规划咨询" }]
	}, {
		"label": "律师",
		"value": "律师",
		"children": [{ "label": "专利律师", "value": "专利律师" }, {
			"label": "知识产权律师",
			"value": "知识产权律师"
		}, { "label": "律师助理", "value": "律师助理" }, { "label": "事务所律师", "value": "事务所律师" }, {
			"label": "法务",
			"value": "法务"
		}]
	}, {
		"label": "翻译",
		"value": "翻译",
		"children": [{ "label": "英语翻译", "value": "英语翻译" }, {
			"label": "日语翻译",
			"value": "日语翻译"
		}, { "label": "韩语/朝鲜语翻译", "value": "韩语/朝鲜语翻译" }, {
			"label": "法语翻译",
			"value": "法语翻译"
		}, { "label": "德语翻译", "value": "德语翻译" }, { "label": "俄语翻译", "value": "俄语翻译" }, {
			"label": "西班牙语翻译",
			"value": "西班牙语翻译"
		}, { "label": "其他语种翻译", "value": "其他语种翻译" }]
	}, {
		"label": "其他咨询类职位",
		"value": "其他咨询类职位",
		"children": [{ "label": "其他咨询/翻译类职位", "value": "其他咨询/翻译类职位" }]
	}]
}, {
	"label": "旅游",
	"value": "旅游",
	"children": [{
		"label": "旅游服务",
		"value": "旅游服务",
		"children": [{ "label": "计调", "value": "计调" }, { "label": "签证专员", "value": "签证专员" }, {
			"label": "导游",
			"value": "导游"
		}, { "label": "预定票务", "value": "预定票务" }, { "label": "讲解员", "value": "讲解员" }]
	}, {
		"label": "旅游产品开发/策划",
		"value": "旅游产品开发/策划",
		"children": [{ "label": "旅游产品经理", "value": "旅游产品经理" }, { "label": "旅游策划师", "value": "旅游策划师" }]
	}, {
		"label": "其他旅游职位",
		"value": "其他旅游职位",
		"children": [{ "label": "其他旅游职位", "value": "其他旅游职位" }]
	}]
}, {
	"label": "其他",
	"value": "其他",
	"children": [{
		"label": "其他职位类别",
		"value": "其他职位类别",
		"children": [{ "label": "其他职位", "value": "其他职位" }]
	}]
}];

export const chatsDataMap = {
	cf_type: {
		"1": "社会招聘",
		"2": "校园招聘",
		"3": "劳动写作"
	},
	online_status: {
		"1": "未确定活动形式",
		"2": "线下活动",
		"3": "活动",
		"4": "线下结合型"
	}
}
//企业性质
// export const companyEmploymentNature = ['民营性质的服务企业', '民营企业', '有限责任公司', '事业单位', '澳资性质的服务企业', '私营企业', '社会团体', '国有性质的企业', '外资性质的服务企业', '股份制企业', '行政机关', '公共就业服务机构', '台资性质的服务企业', '民办非企业等其他性质的服务机构', '国有企业', '其他', '综合性公共就业和人才服务机构', '中外合资的企业', '国有性质的服务企业', '央企', '人才公共服务机构', '港资性质的服务企业', '行业所属服务机构(事业单位)']

export const companyEmploymentNature = ["国有企业", "民营企业", "央企", "事业单位", "其他"];

export const salary_unit = [
	'元/时',
	'元/日',
	'元/月',
	'元/年'
]
export const idMap = [79, 88, 90, 50]


export const advertisementType = {
	"1": "全长",
	"2": "半长",
	"3": "四分之一长",
	"4": "首页轮播1",
	"5": "首页轮播2"
}
