import React from 'react';
import { Button, Input, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import './index.less';

import { useFlats } from 'models/flats';
const { Search } = Input;
const FlatsFilter: React.FC<any> = ({ onChange, selectedKeys }) => {
  const { exportTalentRoom, exportLoading } = useFlats();
  return (
    <div className="flats-list-library-filter">
      <div className="filter-search">
        <Search
          className="filter-item-search"
          onSearch={v => onChange({ search_content: v })}
          allowClear
        />
        <Space size={16}>
          <Button type="primary" loading={exportLoading} onClick={()=>exportTalentRoom()}>
            导出全部
          </Button>
          <Button
           loading={exportLoading} 
            onClick={() => exportTalentRoom({ room_id_list: selectedKeys })}
            type="primary"
            disabled={!selectedKeys.length}>
            导出所选
          </Button>
        </Space>
      </div>
      {/* <div className="filter-item">
       
        <Select
          options={[{label: '年龄不限', value: ''}, ...ageFilter]}
          defaultValue=""
          className="filter-item-select"
          onChange={v => onChange('age', v)}
        />
        <Select
          options={[
            {label: '学历不限', value: ''},
            ...degrees.map(v => ({label: v, value: v})),
          ]}
          defaultValue=""
          className="filter-item-select"
          onChange={v => onChange('degree', v)}
        />
        <Select
          options={[
            {label: '工作经验不限', value: ''},
            ...experiencesFilter,
          ]}
          defaultValue=""
          className="filter-item-select"
          onChange={v => onChange('worked_years', v)}
        />
        <Select
          options={[
            {label: '求职类型不限', value: ''},
            ...employmentType.map(v => ({label: v, value: v})),
          ]}
          defaultValue=""
          className="filter-item-select"
          onChange={v => onChange('work_type', v)}
        />
        <Select
          options={[
            {label: '期望薪资不限', value: ''},
            ...salaryFilter,
          ]}
          defaultValue=""
          className="filter-item-select"
          onChange={v => onChange('salary_range', v)}
        />
      </div> */}
    </div>
  );
};
export default FlatsFilter;
