import React, { useContext, useReducer } from 'react';
import { getDataWithToken, postData, postDataWithToken, putDataWithToken, to, } from '../utils/request';
import { deleteUser, getUserToCache, rememberUser, setUser } from './context';
import { message } from 'antd';
import { encryptAES } from '../utils/crpyto'

import type {
	CreateUserData as typeCreateUserData,
	User as typeUser,
	UserReducerProps as typeUserReducerProps,
} from './types/user';
import type { ReducerAction } from './types/context';
import { idMap } from 'utils/publicData'
import { ValleyContext } from "./valley";

enum requestUrl {
	login = '/token-auth/',
	register = '/user-registration/',
	userInfo = '/users/{user_id}/',
	changePassword = '/users/{user_id}/password/',
	getCode = `/personal/user_produce_verify_code/`
}

const defaultKey = 'c32a193shy2gz48l';

// const idMap = [79, 88, 90];
export const UserContext = React.createContext<Partial<typeUserReducerProps>>({});

export function useUser() {

	const initialState = getUserToCache();
	const { deleteValleyInfo } = useContext(ValleyContext);


	const [user, dispatch] = useReducer((state: typeUser, action: ReducerAction) => {
		switch (action.type) {
			case 'GET_USER': {
				return { ...state, ...action.data, hasSubunit: idMap.includes(action.data?.default_valley_id || 0) };
			}
			case 'VERIFY_USER_FAIL': {
				return { ...state, isAuthed: false };
			}
			case 'LOG_IN': {
				return {
					...state,
					isAuthed: true,
					logged: true, ...action.data,
					hasSubunit: idMap.includes(action.data?.user_valley_id || 0)
				};
			}
			case 'LOG_OUT': {
				return { isAuthed: false, token: '', logged: false, user_id: null, loginLoading: false, hasSubunit: false };
			}
			case 'REGISTER': {
				return { ...state, isAuthed: true, ...action.data };
			}
			case 'GET_USER_START': {
				return { ...state, loginLoading: true };
			}
			case 'GET_USER_END': {
				return { ...state, loginLoading: false };
			}
			case 'GET_SESSION_STORAGE_SUCCESS': {
				return { ...state, getSessionStorage: true, ...action.data };
			}

			default:
				return state;
		}
	}, initialState);


	async function login(
		data: { loginData: { username: string; password: string }; remember: boolean },
		cb?: Function
	) {
		let encryptedData = encryptAES(JSON.stringify(data.loginData), defaultKey)
		let [error, res] = await to(postData(requestUrl.login, { data: { data: encryptedData } }));
		if (!error) {
			rememberUser({ ...res.data, default_valley_id: res.data.user_valley_id }, 7);
			dispatch({ type: 'LOG_IN', data: res.data });
			if (cb) {
				cb();
			}
		}
		return [error, res?.data];
	}

	function setUserAuthSync(data: { token: string; user_id: number, default_valley_id: number }) {
		rememberUser(data, 7);
		dispatch({ type: 'LOG_IN', data: data });
	}

	async function getUserInfo(user_id: number) {
		dispatch({ type: 'GET_USER_START' });
		let url = requestUrl.userInfo.replace('{user_id}', String(user_id));
		let [error, res] = await to(getDataWithToken(url, { concurrent: false }));
		if (!error) {
			dispatch({ type: 'GET_USER', data: res.data });
			localStorage.setItem('circle_id', res.data.circle_id);
			localStorage.setItem('default_valley_id', res.data.default_valley_id);
			dispatch({ type: 'GET_USER_END' });
			return res.data;
		} else dispatch({ type: 'GET_USER_END' });
	}

	async function createUser(data: typeCreateUserData) {
		let [err, res] = await to(postData(requestUrl.register, { data }));
		if (!err) {
			setUser(res.data);
			dispatch({ type: 'LOG_IN', data: res.data });
		}
	}

	async function modifyUserInfo(
		user_id: string,
		data: { name?: string; password?: string; phone?: string }
	) {
		const url = requestUrl.userInfo.replace('{user_id}', user_id);
		let [err] = await to(putDataWithToken(url, { data }));
		if (!err) {
			message.success('修改成功');
			getUserInfo(Number(user_id));
			return true;
		}
	}

	async function changePassword(
		user_id: string,
		data: { old_password?: string; new_password?: string }
	) {

		const url = requestUrl.changePassword.replace('{user_id}', user_id);
		let [err] = await to(putDataWithToken(url, { data }));
		if (!err) {
			await message.success('修改成功，将自动返回首页请重新登录');
			logout()
			deleteValleyInfo?.()
			return true;
		} else {
			// message.error("修改失败")
			console.log(err)
		}
	}

	async function forgetPassword(data: any) {
		const url = requestUrl.register;
		let [err] = await to(postData(`${url}forget_password/`, data));
		if (!err) {
			message.success('修改密码链接已发送至您的邮箱');
		} else {
			message.error("修改密码链接发送失败")
		}
	}

	async function changeforgetPassword(data: any, token: string) {
		const url = requestUrl.register;
		let [err] = await to(postData(`${url}forget_password/${token}/`, data));
		if (!err) {
			message.success('密码修改成功');
		} else {
			message.error("修改失败")
		}
	}

	async function logout() {
		deleteUser();
		dispatch({ type: 'LOG_OUT' });
		//@ts-ignore
		window.location.href = "/guanli"
	}


	async function getCode() {
		const url = requestUrl.getCode;
		const userId = localStorage.getItem("ssbj_admin_user_id")
		const [err] = await to(postDataWithToken(`${url}${userId}/`, { data: {} }));
		if (!err) {
			message.success("验证码已发送至您的手机")
		} else {
			console.log(err)
			// message.error("验证码发送失败")
		}
	}


	return {
		user,
		login,
		setUserAuthSync,
		createUser,
		logout,
		getUserInfo,
		forgetPassword,
		changeforgetPassword,
		modifyUserInfo,
		changePassword,
		getCode
	};
}
